import { Modal, ModalContent } from "@atoms/modal/modal";
import { atom, useRecoilState } from "recoil";
import { TableInfos } from "./create-columns";
import { Frame } from "@atoms/layout/frame";
import { BaseSmall, InfoTiny } from "@atoms/text";
import { Checkbox } from "@atoms/input/input-checkbox";
import { useEffect, useState } from "react";
import { Button } from "@atoms/button/button";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { deepEqual, moveElement } from "@features/utils/divers";
import { CommonApiClient } from "@features/general/common/api-client/api-client";
import toast from "react-hot-toast";
import { useAuth } from "@features/general/auth/state/use-auth";
import { VscThreeBars } from "react-icons/vsc";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export const TableConfigModalAtom = atom<{
  open: boolean;
  tableInfos?: TableInfos;
  commited: boolean;
  codePres: string;
}>({
  key: "TableConfigModalAtom",
  default: { open: false, commited: false, codePres: "" },
});

export const TableConfigModal = () => {
  const [tableConfigtModalAtom, setTableConfigModalAtom] =
    useRecoilState(TableConfigModalAtom);

  const [tempConfig, setTempConfig] = useState<TableInfos | undefined>();

  const [changed, setChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useAuth();

  const handleClose = (comited: boolean) => {
    setTableConfigModalAtom({
      ...tableConfigtModalAtom,
      open: false,
      commited: comited,
    });
  };

  useEffect(() => {
    setTempConfig(tableConfigtModalAtom.tableInfos);
  }, [tableConfigtModalAtom.tableInfos]);

  useEffect(() => {
    setChanged(!deepEqual(tableConfigtModalAtom.tableInfos, tempConfig));
  }, [tempConfig, tableConfigtModalAtom.tableInfos]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 150, // Temps avant activation en millisecondes
        tolerance: 5, // Bouger légèrement ne casse pas le hold
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    setTempConfig((prev) => {
      if (!prev) return prev;

      const oldIndex = prev.tableColumns.findIndex(
        (c) => c.codeCol === active.id
      );

      const newIndex = prev.tableColumns.findIndex(
        (c) => c.codeCol === over.id
      );

      // const tempTab = [...prev.tableColumns];
      // tempTab[oldIndex] = { ...prev.tableColumns[newIndex], pos: oldIndex };
      // tempTab[newIndex] = { ...prev.tableColumns[oldIndex], pos: newIndex };

      const tempTab = moveElement(prev.tableColumns, oldIndex, newIndex).map(
        (c, index) => {
          return { ...c, pos: index };
        }
      );

      return {
        ...prev,
        tableColumns: tempTab,
      };
    });
  };

  return (
    <Modal
      className="sm:max-w-[900px]"
      open={tableConfigtModalAtom.open}
      onClose={() => handleClose(false)}
    >
      <ModalContent title={"Configuration"}>
        {tableConfigtModalAtom.tableInfos && tempConfig && (
          <Frame className="grow flex flex-col gap-2 ">
            <DndContext
              onDragEnd={handleDragEnd}
              sensors={sensors}
              collisionDetection={closestCenter}
            >
              <SortableContext
                items={tempConfig.tableColumns.map((c) => c.codeCol)}
                strategy={rectSortingStrategy}
              >
                <div className="grow grid grid-cols-3 gap-4">
                  {tempConfig.tableColumns.map((c, index) => (
                    <ColUnit
                      c={c}
                      index={index}
                      onChange={(newColumnValue) => {
                        setTempConfig((prev) => {
                          if (prev) {
                            const tempTab = [...prev?.tableColumns];
                            tempTab[index] = newColumnValue;

                            return {
                              ...prev,
                              tableColumns: tempTab,
                            } as TableInfos;
                          }
                          return prev;
                        });
                      }}
                    />
                  ))}
                </div>
              </SortableContext>
            </DndContext>
          </Frame>
        )}
        <div className="w-full flex flex-row gap-2 justify-end mt-2">
          <Button
            disabled={!changed || loading}
            theme="danger"
            data-tooltip="Annuler modifications"
            icon={({ className }) => <XIcon className={className} />}
            onClick={() => setTempConfig(tableConfigtModalAtom.tableInfos)}
          >
            Annuler modifications
          </Button>
          <Button
            disabled={!changed || loading}
            loading={loading}
            theme="valid"
            data-tooltip="Valider modifications"
            icon={({ className }) => <CheckIcon className={className} />}
            onClick={async () => {
              setLoading(true);
              if (tempConfig)
                try {
                  await CommonApiClient.updateTablePres(
                    user!.id,
                    tableConfigtModalAtom.codePres,
                    tempConfig
                  );
                  setLoading(false);
                  toast.success("Présentation du tableau mise à jour.");
                  handleClose(true);
                } catch {
                  toast.error(
                    "Problème rencontré lors de la mise à jour de la présentation du tableau."
                  );
                  setLoading(false);
                }
            }}
          >
            Valider modifications
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

const ColUnit = (props: {
  c: any;
  index: number;
  onChange?: (c: any) => void;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: `${props.c.codeCol}`,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    // boxShadow: transform
    //   ? isDarkMode
    //     ? "0px 5px 15px rgb(235, 32, 32)" // Ombre plus claire en dark mode
    //     : "0px 5px 15px rgba(0, 0, 0, 0.2)"
    //   : "none",
  };
  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className={`relative col-span-1 flex gap-4 items-center bg-gray-100 dark:bg-gray-600 p-2 rounded cursor-pointer
        ${
          isDragging
            ? "shadow-[0px_5px_15px_rgba(0,0,0,0.3)] dark:shadow-[0px_5px_15px_rgba(255,255,255,0.3)]"
            : ""
        }`}
    >
      <span
        className={`h-full text-gray-500 hover:bg-slate-300 p-2 rounded-md dark:hover:bg-slate-400 ${
          isDragging ? "cursor-grabbing" : "cursor-grab"
        }`}
        {...listeners}
      >
        <VscThreeBars className="h-5 w-5" />
      </span>
      <Checkbox
        value={props.c.visible}
        onChange={(newValue) => {
          props.onChange && props.onChange({ ...props.c, visible: newValue });
        }}
      />
      <BaseSmall>{props.c.libCol}</BaseSmall>
      <InfoTiny className="absolute top-0 left-0 m-0 p-2">
        {props.index + 1}
      </InfoTiny>
    </div>
  );
};
