import { fetchServer } from "@features/utils/fetch-server";
import { SalesRevenue } from "../type";

export class DashboardAPIClient {
  static getSalesRevenue = async (
    codeLieu: string,
    canalVente: string,
    typeStat: string,
    periodDeb?: Date | null,
    periodFin?: Date | null
  ) => {
    const data = await fetchServer("/statistics/salesrevenue", {
      method: "POST",
      body: JSON.stringify({
        codeLieu: codeLieu,
        codeCanalVente: canalVente,
        type: typeStat,
        // periodeDeb: "2024-01-15",
        // periodeFin: "2024-01-20",
        //b.dateLivrPrev.getTime() - b.dateLivrPrev.getTimezoneOffset() * 60000
        periodeDeb: periodDeb
          ? new Date(
              periodDeb.getTime() - periodDeb.getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[0]
          : "",

        periodeFin: periodFin
          ? new Date(
              periodFin.getTime() - periodFin.getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[0]
          : "",
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as SalesRevenue[];
  };
}
