import { BookRender } from "@atoms/book";
import { Button } from "@atoms/button/button";
import { InputCounter } from "@atoms/input/input-counter";
import { Frame } from "@atoms/layout/frame";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, BaseBold, BaseSmall, InfoSmall, Section } from "@atoms/text";
import { CheckCircleIcon, TagIcon } from "@heroicons/react/outline";
import { Table } from "@molecules/table";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import {
  ReceptionLine,
  ReceptionUnit,
  RelatedOrder,
} from "@features/reception/types";
import { useReception } from "@features/reception/state/use-reception";
import { toast } from "react-hot-toast";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { useEffect, useState } from "react";
import { SelectedItemReception } from "@features/reception/state/store";
import { focusInput } from "@features/utils/divers";
import { PiWarningBold } from "react-icons/pi";
import { EclatKitModalAtom } from "../eclat-kit-modal";
import { Column } from "@molecules/table/table";
import { ProductsApiClient } from "@features/products/api-client/api-client";

type AffectationModalAtomType = {
  open: boolean;
  receipt: ReceptionUnit | null;
  item: ReceptionLine | null;
  orders: RelatedOrder[];
  queingItem?: ReceptionLine | null;
  total?: number;
  automaticMode?: boolean;
  itemList?: ReceptionLine[];
  kits?: ReceptionLine[];
  autoPrint?: boolean;
};
export const AffectationCmdClientModalAtom = atom<AffectationModalAtomType>({
  key: "AffectationCmdClientModalAtom",
  default: {
    open: false,
    receipt: {} as ReceptionUnit | null,
    item: {} as ReceptionLine | null,
    orders: [] as RelatedOrder[],
    total: 0,
    automaticMode: false,
    itemList: [] as ReceptionLine[],
    autoPrint: true,
  },
});

export const AffectationCmdClientModal = () => {
  const [affectationCmdClientModal, setAffectationCmdClientModal] =
    useRecoilState(AffectationCmdClientModalAtom);
  const setEclatKitModal = useSetRecoilState(EclatKitModalAtom);

  const [ordersList, setOrdersList] = useState<RelatedOrder[][]>();
  const [qteTotalProposee, setQteTotalProposee] = useState(0);
  const [printLoading, setPrintLoading] = useState(false);
  const {
    loading,
    selectedReception,
    getReceipt,
    changeSelectedReception,
    updateLine,
    printLabels,
    receiptLines,
    getRelatedOrders,
  } = useReception();
  const setSelectedItem = useSetRecoilState(SelectedItemReception);

  const { t } = useTranslation();

  useControlledEffect(() => {
    if (affectationCmdClientModal.orders) {
      const commandesRegroupees: Record<string, RelatedOrder[]> =
        affectationCmdClientModal.orders.reduce((acc: any, commande: any) => {
          const key = commande.codeLieuPourQui;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(commande);
          return acc;
        }, {} as Record<string, RelatedOrder[]>);
      const listeDeTableaux: RelatedOrder[][] =
        Object.values(commandesRegroupees);
      setOrdersList(listeDeTableaux);
    }
  }, [affectationCmdClientModal.open, affectationCmdClientModal.item]);

  useEffect(() => {
    if (affectationCmdClientModal.open) {
      setTimeout(() => {
        focusInput("#affecinput-0");
      }, 250);
    } else {
    }
  }, [affectationCmdClientModal.open]);

  useControlledEffect(() => {
    const stockInfos = affectationCmdClientModal.orders.find(
      (el) => el.numCdeCli === ""
    );
    console.log(stockInfos);
    //if (stockInfos) setStockQte(stockInfos.qteProposee);
  }, [affectationCmdClientModal]);

  useControlledEffect(() => {
    if (ordersList)
      setQteTotalProposee(
        ordersList.flat().reduce((sum, order) => sum + order.qteProposee, 0)
      );
  }, [ordersList]);

  // useControlledEffect(() => {
  //   const handleKeyDown = (event: KeyboardEvent) => {
  //     if (event.key === "Tab") {
  //       const rank = parseInt(document.activeElement?.id.split("-")[1] || "-1");
  //       if (rank < 0 || rank >= ordersList.length) {
  //         focusInput("#affecinput-0");
  //       } else focusInput(`#affecinput-${rank + 1}`);
  //     }
  //   };

  //   window.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  return (
    <Modal
      className="sm:max-w-[900px]"
      loading={loading || printLoading}
      open={affectationCmdClientModal.open}
      onClose={() => {
        const activEl = document.activeElement;
        (activEl as HTMLInputElement).blur();
        setAffectationCmdClientModal({
          ...affectationCmdClientModal,
          open: false,
          automaticMode: false,
        });
      }}
    >
      <ModalContent
        title={
          <div className="h-full flex gap-2 ">
            <div
              className="h-full flex flex-col justify-center items-end w-8 "
              data-tooltip={
                affectationCmdClientModal.item
                  ? `<img
                      src="${affectationCmdClientModal.item.imageURL}"     
                      style="max-width: 400px; max-height: 400px; width: 100%; height: auto;"/>`
                  : ""
              }
              data-position="bottom"
            >
              {affectationCmdClientModal.item && (
                <BookRender
                  src={affectationCmdClientModal.item.imageURL}
                  productType={affectationCmdClientModal.receipt?.modeGest}
                />
              )}
            </div>
            <Section className="flex flex-col justify-center ">
              Affectation de {affectationCmdClientModal.item?.desig} -
              {affectationCmdClientModal.item?.ean13}
            </Section>
          </div>
        }
      >
        <div className="h-full flex flex-col gap-2 flex-grow min-h-0">
          {qteTotalProposee !== affectationCmdClientModal.item?.qteRecu && (
            <div className="w-full flex flex-row gap-2 justify-center items-center">
              <PiWarningBold className="w-5 h-5 text-red-400" />
              <BaseBold>
                Veuillez rectifier les affectations afin que la quantité totale
                affectée corresponde à la quantité reçue
              </BaseBold>
            </div>
          )}
          <div className="w-full flex justify-between">
            <BaseBold className="w-full">
              {`${qteTotalProposee}/${affectationCmdClientModal.item?.qteRecu} articles affectés`}
            </BaseBold>
            <div className="w-full flex justify-end gap-4">
              {/* <Button
                disabled={loading}
                theme="danger"
                size="sm"
                icon={(p) => <XCircleIcon {...p} />}
                onClick={() => {
                  setAffectationCmdClientModal({
                    ...affectationCmdClientModal,
                    open: false,
                    automaticMode: false,
                  });
                }}
              >
                {selectedReception.trait ? "Fermer" : t("cancel")}
              </Button> */}
              <Button
                shortcut={["enter"]}
                disabled={
                  loading ||
                  selectedReception.trait ||
                  qteTotalProposee !== affectationCmdClientModal.item?.qteRecu
                }
                theme="valid"
                size="sm"
                icon={(p) => <CheckCircleIcon {...p} />}
                onClick={async () => {
                  if (
                    affectationCmdClientModal.item &&
                    affectationCmdClientModal.receipt
                  ) {
                    const res = await updateLine(
                      {
                        ...affectationCmdClientModal.item,
                        autoLabel:
                          affectationCmdClientModal.receipt &&
                          affectationCmdClientModal.receipt.etiqCdeCli !== 2 &&
                          (affectationCmdClientModal.autoPrint || false) &&
                          !affectationCmdClientModal.automaticMode,
                      },
                      ordersList?.flat().map((r) => {
                        return { ...r, mailCli: r.mailCli ? r.mailCli : "" };
                      }),
                      true
                    );
                    if (!res) {
                      toast.error("Impossible d'affecter les lignes !");
                    } else {
                      toast.success("Lignes affectée avec succès !");
                      if (affectationCmdClientModal.queingItem) {
                        const index = receiptLines.items.findIndex(
                          (el) =>
                            el.linePk ===
                            affectationCmdClientModal.queingItem?.linePk
                        );
                        setSelectedItem({
                          ...affectationCmdClientModal.queingItem,
                          qteRecu:
                            index !== -1
                              ? receiptLines.items[index].qteRecu
                              : 1,
                        });
                      } else {
                        setSelectedItem(null);
                      }
                      if (
                        affectationCmdClientModal.automaticMode &&
                        affectationCmdClientModal.itemList &&
                        affectationCmdClientModal.itemList.length > 1
                      ) {
                        const tempTab = [
                          ...affectationCmdClientModal.itemList,
                        ].slice(1);
                        if (tempTab[0] && affectationCmdClientModal.receipt) {
                          const nextItemOrders = await getRelatedOrders(
                            affectationCmdClientModal.receipt,
                            tempTab[0].ean13,
                            tempTab[0].linePk,
                            tempTab[0].qteRecu,
                            true
                          );
                          setAffectationCmdClientModal({
                            ...affectationCmdClientModal,
                            item: tempTab[0],
                            orders: nextItemOrders,
                            itemList: tempTab,
                          });
                        }
                      } else {
                        const updatedReception = await getReceipt(
                          selectedReception
                        );
                        if (affectationCmdClientModal.automaticMode)
                          printLabels(
                            updatedReception,
                            undefined,
                            undefined,
                            undefined,
                            true
                          );
                        changeSelectedReception(updatedReception);
                        setAffectationCmdClientModal({
                          receipt: null,
                          item: null,
                          orders: [],
                          open: false,
                          queingItem: null,
                        });

                        if (
                          affectationCmdClientModal.automaticMode &&
                          affectationCmdClientModal.kits
                        ) {
                          if (affectationCmdClientModal.kits.length > 0) {
                            setTimeout(() => {
                              setEclatKitModal({
                                open: true,
                                receipt: selectedReception,
                                products: affectationCmdClientModal.kits
                                  ? affectationCmdClientModal.kits
                                  : [],
                              });
                            }, 1000);
                          }
                        }
                      }
                    }
                  }
                }}
              >
                {t("confirm")}
              </Button>
            </div>
          </div>
          <div
            style={{
              overflowY: "auto",
              maxHeight: "500px",
            }}
          >
            {ordersList &&
              ordersList.map((recepLieu, rowIndex) => (
                <Frame className="my-1">
                  <Base>
                    Lieu:
                    <BaseBold> {recepLieu[0].codeLieuPourQui}</BaseBold>
                  </Base>
                  <Table
                    name="affeCliModal"
                    emptyTabText="Aucune commande client associée à cet article"
                    columns={
                      [
                        {
                          title: t("reception.quantityToAffect"),
                          render: (order) => (
                            <div className="w-full max-w-40">
                              <div style={{ maxWidth: 120, minWidth: 120 }}>
                                <InputCounter
                                  disabled={selectedReception.trait}
                                  id={order.htmlId}
                                  size="sm"
                                  max={
                                    ordersList.length === 1
                                      ? affectationCmdClientModal.item?.qteRecu
                                      : order.numCdeCli !== ""
                                      ? order.qteRest
                                      : 9999
                                  }
                                  min={0}
                                  value={order.qteProposee}
                                  onChange={(newValue) => {
                                    let lineIndex = -1;
                                    let newOrders: RelatedOrder[] = [];
                                    setOrdersList(
                                      (
                                        previous: RelatedOrder[][] | undefined
                                      ) => {
                                        // Mettre à jour la liste des commandes
                                        if (previous) {
                                          lineIndex = previous[
                                            rowIndex
                                          ].findIndex(
                                            (l) =>
                                              l.numCdeCli === order.numCdeCli
                                          );
                                          if (lineIndex !== -1) {
                                            newOrders = [...previous[rowIndex]];
                                            const tempOrder = {
                                              ...newOrders[lineIndex],
                                            };
                                            tempOrder.qteProposee = newValue;
                                            tempOrder.nbrEtiq = newValue;

                                            newOrders[lineIndex] = tempOrder;

                                            // stockOrder = {
                                            //   ...previous[i].find(
                                            //     (el: RelatedOrder) =>
                                            //       el.numCdeCli === ""
                                            //   ),
                                            // } as RelatedOrder;

                                            // stockOrder.qteProposee =
                                            //   affectationCmdClientModal.item!
                                            //     .qteRecu -
                                            //   newOrders.reduce(
                                            //     (sum, el) => sum + el.qteProposee,
                                            //     0
                                            //   );
                                            // stockOrder.nbrEtiq =
                                            //   affectationCmdClientModal.item!
                                            //     .qteRecu -
                                            //   newOrders.reduce(
                                            //     (sum, el) => sum + el.qteProposee,
                                            //     0
                                            //   );

                                            // if (
                                            //   stockQte >= 0 &&
                                            //   stockOrder.qteProposee < 0
                                            // ) {
                                            //   toast(
                                            //     "La quantité vers stock ne peut être négative"
                                            //   );
                                            //   return { ...previous };
                                            // }

                                            //Pour monoLieu
                                            const finalTab = [...previous];
                                            if (ordersList.length > 1) {
                                              const stockOrder = {
                                                ...previous[rowIndex].find(
                                                  (el: RelatedOrder) =>
                                                    el.numCdeCli === ""
                                                ),
                                                qteProposee:
                                                  affectationCmdClientModal.item!
                                                    .qteRecu -
                                                  newOrders.reduce(
                                                    (sum, el) =>
                                                      sum + el.qteProposee,
                                                    0
                                                  ),
                                                nbrEtiq:
                                                  affectationCmdClientModal.item!
                                                    .qteRecu -
                                                  newOrders.reduce(
                                                    (sum, el) =>
                                                      sum + el.qteProposee,
                                                    0
                                                  ),
                                              };
                                              const stockIndex = previous[
                                                rowIndex
                                              ].findIndex(
                                                (el: RelatedOrder) =>
                                                  el.numCdeCli === ""
                                              );
                                              if (stockIndex && newOrders)
                                                newOrders[stockIndex] = {
                                                  ...stockOrder,
                                                } as RelatedOrder;
                                            }
                                            finalTab[rowIndex] = [...newOrders];
                                            return finalTab;
                                          } else return { ...previous };
                                        }
                                      }
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          ),
                        },
                        {
                          title: t("reception.infosCommandes"),
                          render: (order) => (
                            <div className="w-full flex flex-col gap-2 max-w-40">
                              {order.numCdeCli !== "" ? (
                                <>
                                  <BaseSmall>
                                    Num cde: {order.numCdeCli}
                                  </BaseSmall>
                                  <BaseSmall>
                                    {order.qteCde} cdé cli{" "}
                                    {order.codeCanalVente}
                                  </BaseSmall>
                                </>
                              ) : (
                                <BaseBold>STOCK</BaseBold>
                              )}
                            </div>
                          ),
                        },
                        {
                          title: t("reception.Client"),
                          orderable: true,
                          render: (order) => (
                            <div className="w-full flex flex-col gap-2 max-w-40">
                              {order.numCdeCli !== "" ? (
                                <>
                                  <Base>{order.nomClient}</Base>
                                  <InfoSmall>
                                    {order.mailCli} {order.telCli}
                                  </InfoSmall>
                                </>
                              ) : (
                                <BaseBold>STOCK</BaseBold>
                              )}
                            </div>
                          ),
                        },

                        {
                          title: "Étiqueter",
                          render: (order, i) => (
                            <div className="w-full flex gap-2 justify-end">
                              <Button
                                className="shrink-0"
                                size="sm"
                                theme="secondary"
                                disabled={loading || order.linePk === ""}
                                onClick={async () => {
                                  setPrintLoading(true);
                                  try {
                                    if (affectationCmdClientModal.item)
                                      if (order.numCdeCli !== "")
                                        printLabels(
                                          undefined,
                                          affectationCmdClientModal.item,
                                          order.nbrEtiq,
                                          order.linePk.split("|")[0]
                                        );
                                      else
                                        await ProductsApiClient.productLabel([
                                          {
                                            ean13:
                                              affectationCmdClientModal.item
                                                .ean13,
                                            nbrEtiq:
                                              affectationCmdClientModal
                                                .orders[0].nbrEtiq,
                                          },
                                        ]);
                                    setPrintLoading(false);
                                  } catch {
                                    setPrintLoading(false);
                                  }
                                }}
                                icon={({ className }) => (
                                  <TagIcon className={className} />
                                )}
                              />
                              <div
                                id="stock-counter"
                                style={{ maxWidth: 96, minWidth: 96 }}
                              >
                                <InputCounter
                                  disabled={selectedReception.trait}
                                  size="sm"
                                  value={order.nbrEtiq}
                                  min={0}
                                  onChange={(newValue) => {
                                    let lineIndex = -1;
                                    let newOrders: RelatedOrder[];
                                    //let stockOrder: RelatedOrder;
                                    setOrdersList(
                                      (
                                        previous: RelatedOrder[][] | undefined
                                      ) => {
                                        if (previous) {
                                          lineIndex = previous[
                                            rowIndex
                                          ].findIndex(
                                            (l: any) =>
                                              l.numCdeCli === order.numCdeCli
                                          );
                                          if (lineIndex !== -1) {
                                            newOrders = [...previous[rowIndex]];
                                            const tempOrder = {
                                              ...newOrders[lineIndex],
                                            };
                                            tempOrder.nbrEtiq = newValue;
                                            newOrders[lineIndex] = tempOrder;

                                            const finalTab = [...previous];
                                            finalTab[rowIndex] = [...newOrders];

                                            return finalTab;
                                          } else {
                                            return previous;
                                          }
                                        }
                                      }
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          ),
                        },
                      ] as Column<RelatedOrder>[]
                    }
                    data={recepLieu}
                  />
                </Frame>
              ))}
          </div>

          {/* BOTTOM PART */}
        </div>
      </ModalContent>
    </Modal>
  );
};
