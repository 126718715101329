import { Shortcut, useShortcuts } from "@features/utils/shortcuts";
import _ from "lodash";
import React, { useEffect } from "react";
import { defaultInputClassName } from "./input-text";

export interface SelectInputProps
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, "size"> {
  highlight?: boolean;
  className?: string;
  size?: "xxs" | "xs" | "sm" | "md" | "lg";
  children?: React.ReactNode;
  shortcut?: Shortcut[];
  placeholder?: string;
}

export default function Select(props: SelectInputProps) {
  const ref = React.useRef<HTMLSelectElement>();

  useEffect(() => {
    if (props.autoFocus) ref?.current?.focus();
  }, [props.autoFocus]);

  useShortcuts(
    !props.disabled && props.shortcut?.length ? [...props.shortcut] : [],
    (e) => {
      ref?.current?.focus();
      ref?.current?.click();
    }
  );

  return (
    <select
      className={
        defaultInputClassName("plain") +
        " cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed " +
        props.className +
        " " +
        (props.size === "sm"
          ? "h-8 py-1 pr-8"
          : props.size === "lg"
          ? "h-11 "
          : " h-9 ") +
        " " +
        (props.highlight && props.value ? " !ring-2 !ring-yellow-500" : "") +
        " " +
        "min-w-[5rem]"
      }
      ref={ref as any}
      {..._.omit(props, "label", "className", "size", "highlight")}
    >
      {props.children}
    </select>
  );
}
