import { Client } from "@features/customer/type";
import { ProductsApiClient } from "../api-client/api-client";
import { ProductType, ProductTypeShort } from "../types";
import { useBaskets } from "./use-baskets";

export const useBasket = (id: string) => {
  const { updateBasket, getBasket, getProductsCount, getProductsPrice } =
    useBaskets();
  const basket = getBasket(id);

  const add = async (
    product: ProductType | ProductTypeShort,
    quantity = 1,
    ref: boolean = true
  ) => {
    updateBasket(id, {
      add: [{ product, count: quantity }],
    });
    if (!product.referGestion && ref)
      await ProductsApiClient.referenceProduct(product.id);
  };

  const remove = (product: ProductType | ProductTypeShort, quantity = 1) => {
    updateBasket(id, {
      remove: [{ product, count: quantity }],
    });
  };

  const putAside = (product: ProductType | ProductTypeShort, quantity = 1) => {
    updateBasket(id, {
      aside: [{ product, count: quantity }],
    });
  };

  const changeStatu = (
    product: ProductType | ProductTypeShort,
    quantity = 1,
    statu: "ACO" | "RES" | "STO"
  ) => {
    updateBasket(id, {
      changeStatu: [{ product, count: quantity, statu: statu }],
    });
  };

  const refresh = async () => {
    let allAside: string[] = [];
    let allInfos: {
      id: string;
      count: number;
      aside?: boolean;
    }[] = [];
    const allIds = basket?.products.map((p) => {
      if (p.aside) allAside.push(p.product.id);
      allInfos.push({
        id: p.product.id,
        count: p.count,
        aside: p.aside,
      });
      return { id: p.product.id };
    });
    const result = await ProductsApiClient.refreshBasket(allIds || []);
    const replace = result.map((product) => {
      const infos = allInfos.find((info) => info.id === product.id);
      return { product: product, count: infos?.count, aside: infos?.aside } as {
        product: ProductType;
        count: number;
        aside?: boolean;
      };
    });

    updateBasket(id, {
      replace,
    });
  };

  const getPrice = () => getProductsPrice(id);
  const getCount = () => getProductsCount(id);

  return {
    basketCustomer:
      basket?.customer ||
      ({
        numCli: null,
        mobil: "",
        addrMail: "",
        nomCli: "",
        prnomCli: "",
      } as Client),
    products: basket?.products || [],
    add,
    remove,
    changeStatu,
    putAside,
    getPrice,
    getCount,
    refresh,
  };
};
