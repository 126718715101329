import { BookRender } from "@atoms/book";
import { Button } from "@atoms/button/button";
import { InputCounter } from "@atoms/input/input-counter";
import { Frame } from "@atoms/layout/frame";
import { Page } from "@atoms/layout/page";
import { Base, BaseSmall, Info, Title } from "@atoms/text";
import { useProduct } from "@features/products/state/use-product";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { usePageNavigation } from "@features/utils/navigation";
import { ArrowRightIcon, CheckCircleIcon } from "@heroicons/react/outline";
import { Form } from "@molecules/form";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StockTab } from "@components/product/stockTab";
import { PageLoader } from "@atoms/layout/page-loader";
import { NotAvailablePage } from "@views/client/not-available";
import { SearchFormFieldType } from "@molecules/form/types";
import toast from "react-hot-toast";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { CommandType } from "@features/supplier/types";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { flushGlobalEffects } from "@features/utils/hooks/use-global-effect";
import { getCommonProductTooltip } from "@features/utils/format/strings";
import { ROUTES } from "@features/routes";
import { InputWithSuggestions } from "@atoms/input/input-with-suggestion";
import { useScan } from "@atoms/input-scan/use-scan";
import { useProducts } from "@features/products/state/use-products";
import { ProductsApiClient } from "@features/products/api-client/api-client";
import { useSetRecoilState } from "recoil";
import { LieuStockAtom } from "@views/client/products/product/lieu-stock-modal";
import { FaBuildingWheat } from "react-icons/fa6";

//Peut être plus tard à factoriser avec la commande --> En attente d'info

export const SupplierCommandOneClickPage = () => {
  const { goToPreviousPage } = usePageNavigation();
  const { id, type } = useParams<{
    id: string;
    type: string;
  }>();

  //const { product, loading } = useProduct(id || "");
  const { product, loading, refresh } = useProduct(id || "", "ORDER");
  const {
    loading: orderLoading,
    getModesExpedition,
    createOrder,
    selectedPrepHeader,
  } = useSuppliers();
  const { current } = useShopLocations();
  const [orderObject, setorderObject] = useState<CommandType | null>(null);
  const navigate = useNavigate();
  const { scanOn } = useScan();
  const [scanValue, setScanValue] = useState("");
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const { getProductScan } = useProducts();
  const setLieuStockModal = useSetRecoilState(LieuStockAtom);

  const [modesExpedition, setModesExpedition] = useState<
    { value: string; label: string }[] | null
  >(null);

  const handleCreerCommandePreparation = async () => {
    if (orderObject && current?.codeLieu) {
      if (await createOrder(orderObject, current?.codeLieu)) {
        toast.success("Préparation de commande fournisseur crée avec succès.");
        flushGlobalEffects();
        if (window.top === window.self) goToPreviousPage("/products");
        else
          window.parent.postMessage(
            { action: "closeModal", product: product },
            "*"
          );
      } else {
        toast.error("Erreur: Impossible de créer la commande fournisseur ");
      }
    }
    return;
  };

  useControlledEffect(() => {
    const isModal = window.top !== window.self;
    if (product !== null) {
      setorderObject({
        ...product,
        ...product.supplementOrder,
        fournReturn: {
          label: product.fourn,
          value: isModal ? selectedPrepHeader.codeFourn : product.codeFourn,
        },
        basket: isModal ? selectedPrepHeader.panie : "",
        autorisation: false,
        motif: "",
        qteOrdered: 1,
        codeModeExpe: isModal
          ? selectedPrepHeader.codeModeExpe
          : product.supplementOrder?.codeModeExpe,
      } as CommandType);
    }
  }, [product]);

  useControlledEffect(() => {
    const getModeExp = async () => {
      setModesExpedition([
        { label: "<Aucun>", value: "" },
        ...(await getModesExpedition()).map((el) => {
          return { label: `${el.lib} (${el.code})`, value: el.code };
        }),
      ]);
    };
    getModeExp();
  }, []);

  useControlledEffect(() => {
    if (!isFirstLoad) refresh();
    setIsFirstLoad(false);
  }, [id, type]);

  const handleSubmit = async (ean: string) => {
    const query = ean;
    setScanValue("");
    if (query.length > 0) {
      await ProductsApiClient.referenceProduct(query);
      const product = await getProductScan(query || "", true);
      if (product)
        navigate(
          ROUTES.SupplierCommandOne.replace(/:id/, product.id).replace(
            /:type/,
            product.modeGest
          )
        );
    }
  };

  const allColumns: Column<CommandType>[] = [
    {
      title: "Article",
      render: (item, { responsive }) =>
        responsive ? (
          <div
            className="text-right"
            onClick={() => {
              navigate(
                ROUTES.Product.replace(/:type/, item.modeGest)
                  .replace(/:ean/, item.id)
                  .replace(/:referGestion/, "true")
              );
            }}
          >
            <Base
              data-html={true}
              data-tooltip={getCommonProductTooltip(item, item.tooltip)}
            >
              {item.designation}
            </Base>
            <br />
            <Info>{item.id}</Info>
          </div>
        ) : (
          <div className="grow flex justify-between">
            <div
              className="flex flex-row items-center space-x-4 cursor-pointer"
              onClick={() => {
                navigate(
                  ROUTES.Product.replace(/:type/, item.modeGest)
                    .replace(/:ean/, item.id)
                    .replace(/:referGestion/, "true")
                );
              }}
            >
              <div className="w-10 print:hidden">
                <BookRender src={item.imageURL} productType={item.modeGest} />
              </div>

              <div className="flex flex-col">
                <Base
                  className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal max-w-xs"
                  data-html={true}
                  data-tooltip={getCommonProductTooltip(item, item.tooltip)}
                >
                  {item.designation}
                </Base>
                <Info>{item.id}</Info>
              </div>
              <div className="bg-amber-500"></div>
            </div>
            <Button
              className="shrink-0 self-center"
              size="md"
              onClick={async (e) => {
                e.stopPropagation();
                const res = await ProductsApiClient.getLieuStock(item.id);
                setLieuStockModal({
                  open: true,
                  product: product,
                  lieuStockList: res,
                });
              }}
              theme="secondary"
              data-tooltip="Stocks des lieux"
              data-tooltip-id="my-tooltip-inline"
              icon={({ className }) => (
                <FaBuildingWheat className={className} />
              )}
            />
          </div>
        ),
    },
    {
      title: `Prix ${product?.devisSymb || "€"}${
        orderObject?.modeGest === "P" || type === "P" ? " PU TTC" : " PU HT"
      }`,
      render: (item) => (
        <Base className="w-24 text-right">
          {item.prixVente.toFixed(current?.devisNbDecim)}{" "}
          {item.devisSymb || "€"}
        </Base>
      ),
    },
    {
      title: "Quant.",
      render: (item) => (
        <div className="flex flex-col justify-center orderItems-center gap-1">
          <div style={{ maxWidth: 128, minWidth: 96 }}>
            <InputCounter
              size={"md"}
              value={item.qteOrdered}
              min={1}
              max={999}
              onChange={(value) => {
                setorderObject((prev) => {
                  if (prev) return { ...prev, qteOrdered: value };
                  return null;
                });
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  if (loading || !modesExpedition) {
    return <PageLoader />;
  }

  if (!product) {
    return <NotAvailablePage />;
  }

  return (
    <Page>
      <div className="w-full flex flex-col md:flex-row gap-16">
        <Title className="w-fit">
          Préparation de commande fournisseur: {product.designation}
        </Title>
        <div className="w-full flex max-w-xl">
          <div className="ml-auto flex">
            <InputWithSuggestions
              inputMode={scanOn ? "none" : undefined}
              ean={true}
              value={scanValue}
              options={[]}
              inputClassName="to-focus !rounded-l-lg !rounded-r-none"
              autoFocus={true}
              placeholder="Changer d'article"
              onChange={(e) => {
                setScanValue(e.target.value);
              }}
            />

            <Button
              className="shrink-0 !rounded-r-lg !rounded-l-none"
              shortcut={["enter"]}
              icon={(p) => <ArrowRightIcon {...p} />}
              onClick={async () => await handleSubmit(scanValue)}
            />
          </div>
        </div>
      </div>{" "}
      <div className="grow flex flex-col gap-2 xl:my-2">
        <Table
          columns={allColumns}
          data={orderObject !== null ? [orderObject] : []}
          notHeightMaxScreen={true}
        />
        <Frame>
          {orderObject !== null && (
            <Form
              readonly={false}
              value={orderObject}
              onChange={(newValue) => {
                let temp = { ...newValue };

                for (const cle of Object.keys(newValue)) {
                  if (Array.isArray(newValue[cle])) {
                    temp[cle] = newValue[cle][0];
                  }
                  if (temp[cle] === undefined) {
                    delete temp[cle];
                  }
                }
                if (newValue.fournReturn !== orderObject.fournReturn) {
                  const newFourn = product.listFourn.find(
                    (el) => el.codeFourn === newValue.fournReturn
                  );
                  temp.dispo = newFourn?.libDispo;
                }

                setorderObject({ ...(temp as CommandType) });
              }}
              fields={[
                {
                  key: "codeModeExpe",
                  label: "Mode d'expédition",
                  placeholder: "expe",
                  type: "select",
                  options: modesExpedition || [],
                },
                {
                  key: "fournReturn",
                  label: "Fournisseur *",
                  type: "select",
                  placeholder: "Fournisseur...",
                  options: product.listFourn.map((el) => {
                    return { label: el.raisSocial, value: el.codeFourn };
                  }),
                } as SearchFormFieldType,
                {
                  key: "basket",
                  label: "Panier",
                  type: "text",
                },
              ]}
            />
          )}
        </Frame>

        <div className="w-full flex justify-evenly mb-2 xl:mt-2">
          <Button
            onClick={(e) => {
              flushGlobalEffects();
              goToPreviousPage("/products");
            }}
            theme="danger"
          >
            Annuler
          </Button>
          <Button
            disabled={
              orderLoading ||
              (orderObject && !orderObject.fournReturn ? true : false)
            }
            icon={(p) => <CheckCircleIcon {...p} />}
            onClick={() => {
              if (orderObject && orderObject.fournReturn) {
                handleCreerCommandePreparation();
              } else {
                toast.error("Veuillez renseigner le fournisseur.");
              }
            }}
          >
            Valider
          </Button>
        </div>
      </div>
      {product !== null && orderObject && (
        <div className="flex flex-col sm:flex-row gap-2 xl:gap-4 justify-around">
          <Frame className="grow">
            <Form
              value={orderObject}
              onChange={() => {}}
              readonly={true}
              fields={[
                {
                  label: "Editeur / Fabricant",
                  key: type === "L" ? "editeur" : "codeFab",
                  type: "text",
                },
                {
                  label: "Zone",
                  key: "numZone",
                  type: "text",
                  alwaysVisible: true,
                },
                {
                  label: "Rayon",
                  key: "libRayon",
                  type: "text",
                  alwaysVisible: true,
                  render: (v, vs) => {
                    return <BaseSmall>{`${v} (${vs.codeRayon})`}</BaseSmall>;
                  },
                },
                {
                  label: "Première entrée en stock",
                  key: "datePremEntre",
                  type: "date",
                  alwaysVisible: true,
                },
                {
                  label: "Date dernière commande",
                  key: "dateDernCde",
                  type: "date",
                  alwaysVisible: true,
                },
                {
                  label: "Dernière réception",
                  key: "dateDernRecep",
                  type: "date",
                  alwaysVisible: true,
                },

                {
                  label: "Date dernier retour",
                  key: "dateDernRet",
                  type: "date",
                  alwaysVisible: true,
                },
                {
                  label: "Disponibilité fournisseur",
                  key: "dispo",
                  alwaysVisible: true,
                },
                {
                  label: "Quantité vendue",
                  key: "qteVente",
                  type: "number",
                  alwaysVisible: true,
                },
                {
                  label: "Quantité en préparation cde fourn",
                  key: "qtePrepCdePlusTrans",
                  type: "number",
                  alwaysVisible: true,
                },
                {
                  label: "Couverture prévisionnelle (jours)",
                  key: "couvPrevis",
                  type: "number",
                  alwaysVisible: true,
                },
              ]}
            />
          </Frame>
          <div className="flex-auto">
            <StockTab product={product} />
          </div>
        </div>
      )}
    </Page>
  );
};
