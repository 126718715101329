import { Button } from "@atoms/button/button";
import { Title } from "@atoms/text";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { XIcon } from "@heroicons/react/outline";
import { Table } from "@molecules/table";
import { Column, Pagination } from "@molecules/table/table";
import { useState } from "react";
import { createPortal } from "react-dom";
import { atom, useRecoilState } from "recoil";

type TabVertModalType = {
  open: boolean;
  columns: Column<any>[];
  title: string;
  onTabClick?: (el: any) => void;
  getData?: (
    page: number,
    perPage: number,
    order: "ASC" | "DESC" | undefined,
    orderBy: number | undefined,
    filters: any
  ) => Promise<{
    items: any[];
    pageNumber: number;
    pageSize: number;
    pageCount: number;
    totalRows: number;
    rowsReturned: number;
  }>;
};

export const TabVertModalAtom = atom<TabVertModalType>({
  key: "TabVertModal",
  default: { open: false, title: "", columns: [] },
});

export const TabVertModal = () => {
  const [tabVertModal, setTabVertModal] = useRecoilState(TabVertModalAtom);
  const [tabName, setTabName] = useState("");
  const [order, setOrder] = useState<{
    orderDir: "ASC" | "DESC" | undefined;
    orderBy?: string;
  }>({ orderDir: "ASC", orderBy: undefined });
  const [data, setData] = useState<{
    items: any[];
    pageNumber: number;
    pageSize: number;
    pageCount: number;
    totalRows: number;
    rowsReturned: number;
  }>();

  const handleClose = () => {
    setTabName("");
    setTabVertModal((prev) => ({
      ...prev,
      open: false,
      getData: undefined,
      onTabClick: undefined,
    }));
    setTimeout(() => {
      setData({
        items: [],
        pageNumber: 1,
        pageSize: 15,
        pageCount: 1,
        totalRows: 0,
        rowsReturned: 0,
      });
      setTabVertModal({
        open: false,
        columns: [],
        getData: undefined,
        title: tabVertModal.title || "",
      });
    }, 300); // Délai pour permettre l'animation
  };

  useControlledEffect(() => {
    if (tabVertModal.open)
      setTabName(
        Math.random()
          .toString(36)
          .substring(2, 2 + 5)
      );
  }, [tabVertModal]);

  return createPortal(
    <div
      className={`fixed inset-0 z-50 grid place-items-center min-h-screen my-4 bg-opacity-50 ${
        tabVertModal.open
          ? "opacity-100 scale-100"
          : "hidden opacity-0 scale-95"
      } transition-all duration-300`}
      onClick={handleClose}
    >
      {tabVertModal.open && (
        <div
          className="bg-white dark:bg-slate-900 rounded-lg shadow-lg w-4/5 max-w-[1600px] max-h-[800px] p-6 transform transition-all duration-300"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-full flex justify-end">
            <Button
              theme="default"
              size="md"
              className="!bg-slate-300 !h-7 !w-7 border-0 dark:!bg-slate-600 !rounded-full text-slate-600 dark:text-slate-300 hover:opacity-75 focus:outline-none "
              onClick={handleClose}
              shortcut={["esc"]}
              icon={(p) => <XIcon {...p} />}
            />
          </div>

          <div className="max-h-[750px] flex flex-col">
            <Title>{tabVertModal.title}</Title>
            <Table
              className="w-full overflow-x-scroll"
              name={tabName}
              showPagination={"full"}
              headerPagination={true}
              columns={tabVertModal.columns}
              data={data?.items || []}
              searchModeEnabled={true}
              total={data?.totalRows || 0}
              onClick={(el) => {
                tabVertModal.onTabClick && tabVertModal.onTabClick(el);
                handleClose();
              }}
              initialPagination={
                {
                  total: 0,
                  page: 1,
                  perPage: 10,
                  order: order.orderDir,
                } as Pagination
              }
              onRequestData={async ({
                page,
                perPage,
                order,
                orderBy,
                filters,
              }) => {
                if (tabVertModal.getData) {
                  const res = await tabVertModal.getData(
                    page,
                    perPage,
                    order,
                    orderBy,
                    filters
                  );
                  setOrder({ orderDir: order });
                  if (res) setData({ ...res });
                }
              }}
              onChangeFilters={async (filters) => {
                if (tabVertModal.getData) {
                  const res = await tabVertModal.getData(
                    1,
                    15,
                    "ASC",
                    0,
                    filters
                  );
                  if (res) setData({ ...res });
                }
              }}
            />
          </div>
        </div>
      )}
    </div>,
    document.body
  );
};
