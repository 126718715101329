const Env = {
  server: "", // Used in CI to call relative path for server
  version: "20250328-164413",
  //If domain is inferenceweb-integration.praxiel.fr then server is "https://api.infew.praxiel.fr"
  ...(document.location.hostname.includes("inferenceweb-integration.praxiel.fr")
    ? {
        server: "https://api.infew.praxiel.fr",
      }
    : {}),
  //If domain is inferenceweb.praxiel.fr then server is "https://api.inferencewe.praxiel.fr"
  ...(document.location.hostname.includes("inferenceweb.praxiel.fr")
    ? {
        server: "https://api.inferenceweb.praxiel.fr",
      }
    : {}),
  //If domain is inferenceweb.lagrandlibrairie.com then server is "https://api.infew.lagrandlibrairie.com"
  ...(document.location.hostname.includes("inferenceweb.lagrandlibrairie.com")
    ? {
        server: "https://api.infew.lagrandlibrairie.com",
      }
    : {}),
  //If domain is inferenceweb.majuscule-cambrai.com then server is "https://api.infew.majuscule-cambrai.com"
  ...(document.location.hostname.includes("inferenceweb.majuscule-cambrai.com")
    ? {
        server: "https://api.infew.majuscule-cambrai.com",
      }
    : {}),
  //If domain is inferenceweb.recette.multi.praxiel.fr then server is "https://api.infew.recette.multi.praxiel.fr"
  ...(document.location.hostname.includes("inferenceweb.recette.multi.praxiel.fr")
    ? {
        server: "https://api.infew.recette.multi.praxiel.fr",
      }
    : {}),
    isBeta:false,
    defaultExternalDB: true,
    maxInactiv: 45,
    lastRefresh: 0,
    refreshFreq: 30,
    debugAlert: false,
};

export default Env;

