import { Page } from "@atoms/layout/page";
import { BaseBold, InfoSmall, Title } from "@atoms/text";
import { useCustomer } from "@features/customer/state/use-customer";
import { SearchForm } from "@molecules/form";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { Column } from "@molecules/table/table";
import { Client } from "@features/customer/type";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@features/routes";
import { Button } from "@atoms/button/button";
import { ArrowRightIcon } from "@heroicons/react/outline";
import { useRecoilState } from "recoil";
import { persistAtom } from "@features/utils/hooks/use-local-storage";

export const ClientsPage = () => {
  const {
    loading,
    customerSearch,
    setCustomerSearch,
    customerSearchResult,
    setCustomerSearchResult,
    searchCustomer,
    setSelectedCustomer,
  } = useCustomer();

  const SearchTriggered = persistAtom<boolean>("user", {
    key: "SendOrderHeaders",
    default: false,
  });
  const [searchTriggered, setSearchTriggered] = useRecoilState(SearchTriggered);
  const navigate = useNavigate();

  const clientColumns: Column<Client>[] = [
    {
      title: "Numéro client",
      render: (client) => client.numCli,
    },
    {
      title: "Nom",
      render: (client) => (
        <BaseBold>{`${client.prnomCli} ${client.nomCli}`}</BaseBold>
      ),
    },

    {
      title: "Mail",
      render: (client) => client.addrMail,
    },
    {
      title: "Tél.",
      render: (client) => client.tel,
    },
    {
      title: "Canal Vente",
      render: (client) => client.codeCanalVente,
    },
    {
      title: "Addresse",
      render: (client) =>
        [client["addrVille"] || "", client["addrCp"] || ""]
          .filter((a) => a.trim())
          .join(", "),
    },
    {
      title: "",
      render: () => (
        <div className="w-full flex justify-center">
          <Button
            size="sm"
            onClick={() => navigate(ROUTES.ClientInfosPage)}
            icon={(p) => <ArrowRightIcon {...p} />}
            data-tooltip="Accéder à la page du client"
          />
        </div>
      ),
    },
  ];

  return (
    <Page loading={loading}>
      <Title>Clients</Title>
      <div className="mt-6">
        <SearchForm
          onChange={(t) => {
            setCustomerSearch(t as any as { query: string });
          }}
          onSearch={async () => {
            setSearchTriggered(true);
            setCustomerSearchResult(await searchCustomer(customerSearch));
          }}
          value={customerSearch}
          fields={[
            {
              key: "query",
              type: "text",
              autoFocus: true,
              placeholder: "Chercher un client",
            },
          ]}
        />

        {searchTriggered && (
          <TableGridSwitch
            data={customerSearchResult}
            gridMode={true}
            tableColumns={clientColumns}
            onClick={(el) => {
              setSelectedCustomer(el);
              navigate(ROUTES.ClientInfosPage);
            }}
            renderGrid={(item) => (
              <div
                className={
                  "grow h-32 flex flex-col lg:gap-1 p-2 bg-white border-2 border-blue-100 rounded-lg hover:bg-slate-200 hover:border-white hover:cursor-pointer"
                }
              >
                <BaseBold className="whitespace-nowrap text-ellipsis overflow-hidden">
                  {[item["nomCli"], item["prnomCli"]]
                    .filter((a) => a.trim())
                    .join(" ")}
                </BaseBold>
                {item.numCli && (
                  <InfoSmall className="whitespace-nowrap text-ellipsis overflow-hidden">
                    Num. client: {item.numCli}
                  </InfoSmall>
                )}
                <InfoSmall className="whitespace-nowrap text-ellipsis overflow-hidden">
                  Canal vente: {item.codeCanalVente}
                </InfoSmall>
                <InfoSmall className="whitespace-nowrap text-ellipsis overflow-hidden">
                  {item.addrMail}
                </InfoSmall>
                <InfoSmall className="whitespace-nowrap text-ellipsis overflow-hidden">
                  {item.mobil}
                </InfoSmall>

                <InfoSmall className="whitespace-nowrap text-ellipsis overflow-hidden">
                  {[item["addrVille"] || "", item["addrCp"] || ""]
                    .filter((a) => a.trim())
                    .join(", ")}
                </InfoSmall>
              </div>
            )}
            gridClassName={
              "grid gap-4 grid-cols-1 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-5"
            }
          />
        )}
      </div>
    </Page>
  );
};
