import { Modal, ModalContent } from "@atoms/modal/modal";
import { useProduct } from "@features/products/state/use-product";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { atom, useRecoilState } from "recoil";
import { StockTab } from "./stockTab";
import { Frame } from "@atoms/layout/frame";
import { Form } from "@molecules/form";
import { BaseSmall } from "@atoms/text";

export const ProductInfosAtom = atom({
  key: "ProductInfosAtom",
  default: {
    open: false,
    ean: "",
    modeGest: "",
  },
});

export const ProductInfosModal = () => {
  const [productInfosModal, setProductInfosModal] =
    useRecoilState(ProductInfosAtom);
  const { loading, product, refresh } = useProduct(
    productInfosModal.ean,
    undefined,
    productInfosModal.modeGest,
    true
  );

  const handleClose = () => {
    setProductInfosModal({ open: false, ean: "", modeGest: "" });
  };

  useControlledEffect(() => {
    if (productInfosModal.open) refresh();
  }, [productInfosModal.open]);

  return (
    <Modal
      className="min-w-[1200px] sm:max-w-[1200px]"
      open={productInfosModal.open}
      loading={loading}
      onClose={() => handleClose()}
    >
      <ModalContent
        title={`Informations sur: ${product ? product.designation : ""}`}
      >
        {product && (
          <div className="w-full flex flex-col gap-4">
            <Frame>
              <Form
                value={product}
                onChange={() => {}}
                readonly={true}
                fields={[
                  {
                    label: "Editeur / Fabricant",
                    key:
                      productInfosModal.modeGest === "L"
                        ? "editeur"
                        : "codeFab",
                    type: "text",
                  },
                  {
                    label: "Zone",
                    key: "numZone",
                    type: "text",
                    alwaysVisible: true,
                  },
                  {
                    label: "Rayon",
                    key: "libRayon",
                    type: "text",
                    alwaysVisible: true,
                    render: (v, vs) => {
                      return <BaseSmall>{`${v} (${vs.codeRayon})`}</BaseSmall>;
                    },
                  },
                  {
                    label: "Première entrée en stock",
                    key: "datePremEntre",
                    type: "date",
                    alwaysVisible: true,
                  },
                  {
                    label: "Date dernière commande",
                    key: "dateDernCde",
                    type: "date",
                    alwaysVisible: true,
                  },
                  {
                    label: "Dernière réception",
                    key: "dateDernRecep",
                    type: "date",
                    alwaysVisible: true,
                  },

                  {
                    label: "Date dernier retour",
                    key: "dateDernRet",
                    type: "date",
                    alwaysVisible: true,
                  },
                  {
                    label: "Disponibilité fournisseur",
                    key: "dispo",
                    alwaysVisible: true,
                  },
                  {
                    label: "Quantité vendue",
                    key: "qteVente",
                    type: "number",
                    alwaysVisible: true,
                  },
                  {
                    label: "Quantité en préparation cde fourn",
                    key: "qtePrepCdePlusTrans",
                    type: "number",
                    alwaysVisible: true,
                  },
                  {
                    label: "Couverture prévisionnelle (jours)",
                    key: "couvPrevis",
                    type: "number",
                    alwaysVisible: true,
                  },
                ]}
              />
            </Frame>
            <Frame>
              <StockTab product={product} />
            </Frame>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};
