import { useState } from "react";
import { CustomerAPIClient } from "../api-client/api-client";
import { Client, Order } from "../type";
import { useRecoilState } from "recoil";
import {
  CustomerSearch,
  CustomerSearchResult,
  SelectedCustomer,
} from "./store";
import { CommonPagination } from "@features/types";
import { LoadingState } from "@features/utils/store/loading-state-atom";

export const useCustomer = () => {
  const [userHistory, setUserHistory] = useState<Order[]>();
  const [loading, setLoading] = useRecoilState(
    LoadingState(["useCustomer", false])
  );
  const [historyPagination, setHistoryPagination] =
    useState<CommonPagination>();
  const [selectedCustomer, setSelectedCustomer] =
    useRecoilState(SelectedCustomer);

  const [customerSearch, setCustomerSearch] = useRecoilState(CustomerSearch);
  const [customerSearchResult, setCustomerSearchResult] =
    useRecoilState(CustomerSearchResult);

  const searchCustomer = async (search: any, onlyCpt?: boolean) => {
    if (search.query.trim()) {
      let res = (await CustomerAPIClient.searchCustomer(search, "CPT")).filter(
        (el) => el.prnomCli !== "" || el.nomCli || "" || el.addrMail !== ""
      );
      if (onlyCpt) res = res.filter((c) => c.codeCanalVente === "CPT");
      return res;
      //Filtre temporaire pour renvoyer que des cartouches avec des infos --> plus joli pour la présentation
    } else {
      return [];
    }
  };

  const getCustomerSuggestion = async (search: { query: string }) => {
    if (search.query.trim()) {
      return (
        await CustomerAPIClient.getClientSuggestion(search.query, "CPT")
      ).filter(
        (el) => el.prnomCli !== "" || el.nomCli || "" || el.addrMail !== ""
      ); //Filtre temporaire pour renvoyer que des cartouches avec des infos --> plus joli pour la présentation
    } else {
      return null;
    }
  };

  const createCustomer = async (infos: Client) => {
    return await CustomerAPIClient.createCustomer(infos);
  };

  const getCustomerStatus = async (clientId: string) => {
    return await CustomerAPIClient.getCustomerStatus(clientId);
  };

  const getCustomerBlocList = async () => {
    return await CustomerAPIClient.getCustomerBlocList();
  };

  const getCustomerOrdersHistory = async (
    clientID: string,
    pageNumber: number = 1,
    pageSize: number = 20,
    mail: string = "",
    phone: string = ""
  ) => {
    setLoading(true);
    try {
      const { pagination, items } =
        await CustomerAPIClient.getUserCommandHistory(
          clientID,
          pageNumber,
          pageSize,
          mail,
          phone
        );
      setUserHistory(items);
      setHistoryPagination(pagination);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return {
    loading,
    searchCustomer,
    customerSearch,
    setCustomerSearch,
    customerSearchResult,
    setCustomerSearchResult,
    getCustomerSuggestion,
    createCustomer,
    getCustomerStatus,
    getCustomerBlocList,
    getCustomerOrdersHistory,
    userHistory,
    historyPagination,
    selectedCustomer,
    setSelectedCustomer,
  };
};
