import { Checkbox } from "@atoms/input/input-checkbox";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Tabs from "@atoms/tabs";
import { Info, InfoSmallBold } from "@atoms/text";
import { useLoyalty } from "@features/customer/state/use-loyalty";
import { LoyaltyMvt, LoyaltyVente } from "@features/customer/type";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { toDateISOFr } from "@features/utils/format/dates";
import { Table } from "@molecules/table";
import { Column, PaginationAnswer } from "@molecules/table/table";
import { useState } from "react";
import { atom, useRecoilState } from "recoil";

type LoyaltyModalType = {
  open: boolean;
  numCli?: string;
  cardId?: string;
};

export const LoyaltyModalModalAtom = atom<LoyaltyModalType>({
  key: "LoyaltyModalModal",
  default: { open: false },
});

export const LoyaltyModal = () => {
  const [loyaltyModal, setLoyaltyModal] = useRecoilState(LoyaltyModalModalAtom);
  const [tabIndex, setTabIndex] = useState("vente");
  const { loading, getLoyaltyHistoVentes, getLoyaltyMvts } = useLoyalty();
  const [histoVentes, setHistoVentes] = useState<
    PaginationAnswer & { items: LoyaltyVente[] }
  >({
    pageNumber: 0,
    pageSize: 0,
    pageCount: 0,
    totalRows: 0,
    rowsReturned: 0,

    items: [],
  });
  const [histoMvt, seyHistoMvt] = useState<
    PaginationAnswer & { items: LoyaltyMvt[] }
  >({
    pageNumber: 0,
    pageSize: 0,
    pageCount: 0,
    totalRows: 0,
    rowsReturned: 0,

    items: [],
  });
  const { current } = useShopLocations();

  const venteColumns: Column<LoyaltyVente>[] = [
    {
      id: "dateAchat",
      title: "Date achat",
      type: "date",
      orderable: true,
      render: (p) => toDateISOFr(p.dateVente.toString()),
    },
    {
      title: "Article",
      searchIds: ["article"],
      orderable: true,
      render: (p) => (
        <div className="grow max-w-60 flex flex-col items-start whitespace-nowrap verflow-hidden">
          <InfoSmallBold
            className="overflow-hidden cursor-text text-ellipsis max-w-full print:whitespace-normal"
            data-tooltip={p.desig}
          >
            {p.desig}
          </InfoSmallBold>
          <Info>{p.ean13}</Info>
        </div>
      ),
    },

    {
      id: "numTicket",
      title: "N° ticket",
      orderable: true,
      render: (p) => p.numTicket,
    },

    {
      id: "codeRayon",
      orderable: true,
      title: "Rayon",
      render: (p) => p.codeRayon,
    },
    {
      id: "qte",
      title: "Quantité",
      orderable: true,
      type: "number",
      render: (p) => p.qte,
    },
    {
      id: "caTTC",
      title: "CA TTC",
      orderable: true,
      type: "price",
      render: (p) =>
        `${p.cattc.toFixed(current?.devisNbDecim)}
          ${current?.devisSymb || "€"}`,
    },
    {
      id: "fidel",
      title: "Fidélité",
      type: "boolean",
      render: (p) => (
        <div className="w-full flex justify-center">
          <Checkbox disabled={true} value={p.fidel} />
        </div>
      ),
    },
  ];

  const mvtColumns: Column<LoyaltyMvt>[] = [
    {
      id: "dateMvt",
      title: "Date Mouvement",
      type: "date",
      render: (p) => toDateISOFr(p.dateMvt.toString()),
    },
    {
      title: "Article",
      searchIds: ["article"],
      orderable: true,
      render: (p) => (
        <div className="grow max-w-60 flex flex-col items-start whitespace-nowrap verflow-hidden">
          <InfoSmallBold
            className="overflow-hidden cursor-text text-ellipsis max-w-full print:whitespace-normal"
            data-tooltip={p.desig}
          >
            {p.desig}
          </InfoSmallBold>
          <Info>{p.ean13}</Info>
        </div>
      ),
    },
    {
      id: "nomLieu",
      title: "Lieu",
      render: (p) => `${p.nomLieu} (${p.codeLieu})`,
    },
    {
      id: "typeMvt",
      title: "Type mouvement",
      render: (p) => `${p.libTypeMvt} (${p.codeTypeMvt})`,
    },

    {
      id: "numTicket",
      title: "N° ticket",
      render: (p) => p.numTicket,
    },
    {
      id: "numTicket",
      title: "N° ticket",
      render: (p) => p.numTicket,
    },

    {
      id: "numCheque",
      title: "N° chèque",
      type: "string",
      render: (p) => p.numCheque,
    },
    {
      id: "soldeAvant",
      title: "Solde avant",
      type: "number",
      render: (p) => p.soldeAvant,
    },
    {
      id: "mvt",
      title: "Mouvement",
      type: "number",
      render: (p) => p.mvt,
    },
    {
      id: "soldeApres",
      title: "Solde après",
      type: "number",
      render: (p) => p.soldeApres,
    },
  ];
  const handleClose = () => {
    setLoyaltyModal({ open: false, numCli: undefined, cardId: undefined });
  };

  return (
    <Modal
      className="min-w-[1200px] sm:max-w-[900px]"
      open={loyaltyModal.open}
      onClose={() => handleClose()}
      loading={loading}
    >
      <ModalContent title={"Suivi carte de fidélité client"}>
        <div className="grow flex flex-col justify-center">
          <Tabs
            className="self-center"
            tabs={[
              { value: "vente", label: "Historique des achats" },
              { value: "mvt", label: "Historique des mouvements" },
            ]}
            value={tabIndex}
            onChange={async (v) => setTabIndex(v.toString())}
          />
          {loyaltyModal.open && tabIndex === "vente" && (
            <Table
              className="w-full overflow-x-scroll"
              name="loyaltyHistoVente"
              columns={venteColumns}
              data={histoVentes.items}
              showPagination="full"
              headerPagination={true}
              searchModeEnabled={true}
              total={histoVentes.totalRows}
              onRequestData={async ({
                page,
                perPage,
                order,
                orderBy,
                filters,
              }) => {
                const res = await getLoyaltyHistoVentes(
                  {
                    pageNumber: page,
                    pageSize: perPage,
                    orderDir: order,
                    orderBy: orderBy ? orderBy.toString() : "",
                  },
                  {
                    ...filters,
                    numCli: loyaltyModal.numCli,
                  }
                );
                if (res) setHistoVentes(res);
              }}
              onChangeFilters={async (filters) => {
                console.log(filters);
                const res = await getLoyaltyHistoVentes(undefined, {
                  ...filters,
                  numCli: loyaltyModal.numCli,
                });
                if (res) setHistoVentes(res);
              }}
            />
          )}
          {loyaltyModal.open && tabIndex === "mvt" && (
            <Table
              className="w-full overflow-x-scroll"
              name="loyaltyHistoMvt"
              columns={mvtColumns}
              data={histoMvt.items}
              showPagination="full"
              headerPagination={true}
              searchModeEnabled={true}
              total={histoMvt.totalRows}
              onRequestData={async ({
                page,
                perPage,
                order,
                orderBy,
                filters,
              }) => {
                const res = await getLoyaltyMvts(
                  {
                    pageNumber: page,
                    pageSize: perPage,
                    orderDir: order,
                    orderBy: orderBy ? orderBy.toString() : "",
                  },
                  {
                    ...filters,
                    numCarte: loyaltyModal.cardId,
                  }
                );
                if (res) seyHistoMvt(res);
              }}
              onChangeFilters={async (filters) => {
                const res = await getLoyaltyMvts(undefined, {
                  ...filters,
                  numCarte: loyaltyModal.cardId,
                });
                if (res) seyHistoMvt(res);
              }}
            />
          )}
        </div>
      </ModalContent>
    </Modal>
  );
};
