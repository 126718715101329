import { InputCounter } from "@atoms/input/input-counter";
import { InputEdit } from "@atoms/input/input-edit";
import { Base } from "@atoms/text";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { useState } from "react";
import _ from "lodash"; // Assurez-vous que Lodash est installé.

export type ColumnInfo = {
  title: string;
  key: string;
  type?: string;
  editable?: boolean;
};

export const TableauBrun = <T,>({
  columnInfos,
  rows,
  getData,
}: {
  columnInfos: ColumnInfo[];
  rows: T[];
  getData?: () => Promise<any>;
}) => {
  const [data, setData] = useState<T[]>([...rows]);
  console.log(data);
  const [savedData, setSavedData] = useState<T[]>([]);
  let componentColumns: Column<T>[] = [];
  const handleChange = (key: keyof T, row: T, newValue: any) => {
    setData((previous) =>
      previous.map((el) =>
        _.isEqual(el, row) ? { ...el, [key]: newValue } : el
      )
    );
  };

  const createColumn = (column: ColumnInfo): Column<T> => {
    switch (column.type) {
      case "numeric":
        return {
          title: column.title,
          orderable: true,
          render: (rowData: T) =>
            column.editable ? (
              <div>
                <InputCounter
                  value={(rowData as any)[column.key]}
                  onChange={(value: number) =>
                    handleChange(column.key as keyof T, rowData, value)
                  }
                />
              </div>
            ) : (
              <div className="w-full text-end">
                <Base>{(rowData as any)[column.key]}</Base>
              </div>
            ),
        };
      default:
        return {
          title: column.title,
          orderable: true,
          render: (rowData: T) =>
            column.editable ? (
              <div>
                <InputEdit
                  value={(rowData as any)[column.key]}
                  options={[]}
                  onStartEdit={() => setSavedData(data)}
                  onStopEdit={() => {
                    setData(savedData);
                  }}
                  onChange={(e) =>
                    handleChange(column.key as keyof T, rowData, e.target.value)
                  }
                />
              </div>
            ) : (
              <div>
                <Base>{(rowData as any)[column.key]}</Base>
              </div>
            ),
        };
    }
  };

  if (columnInfos) componentColumns = columnInfos.map((c) => createColumn(c));

  return (
    <Table
      columns={componentColumns}
      data={data}
      showPagination={true}
      headerPagination={true}

      // onRequestData={async ({ page, perPage, order, orderBy }) => {
      //   if (getData) {
      //     const res = await getData();
      //     setData(res);
      //   }
      // }}
    />
  );
};
