import { LoadingState } from "@features/utils/store/loading-state-atom";
import { useRecoilState } from "recoil";
import { CustomerAPIClient } from "../api-client/api-client";
import { CommonPaginationRequest } from "@features/types";

export const useLoyalty = () => {
  const [loading, setLoading] = useRecoilState(
    LoadingState(["useLoyalty", false])
  );
  const getLoyaltyCardInfos = async (cardId: string) => {
    setLoading(true);
    try {
      return await CustomerAPIClient.getLoyaltyCardInfos(cardId);
    } catch {
      setLoading(false);
    }
  };

  const getLoyaltyHistoVentes = async (
    pagination?: CommonPaginationRequest,
    filters?: any
  ) => {
    setLoading(true);
    try {
      const res = await CustomerAPIClient.getLoyaltyHistoVentes(
        pagination,
        filters
      );
      setLoading(false);
      return res;
    } catch {
      setLoading(false);
    }
  };

  const getLoyaltyMvts = async (
    pagination?: CommonPaginationRequest,
    filters?: any
  ) => {
    setLoading(true);
    try {
      const res = await CustomerAPIClient.getLoyaltyMvts(pagination, filters);
      setLoading(false);
      return res;
    } catch {
      setLoading(false);
    }
  };

  return {
    loading,
    getLoyaltyCardInfos,
    getLoyaltyHistoVentes,
    getLoyaltyMvts,
  };
};
