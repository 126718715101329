import { Button } from "@atoms/button/button";
import { InputCounter } from "@atoms/input/input-counter";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Info } from "@atoms/text";
import { ProductsApiClient } from "@features/products/api-client/api-client";
import { useReception } from "@features/reception/state/use-reception";
import { focusInput } from "@features/utils/divers";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { useState } from "react";
import { atom, useRecoilState } from "recoil";

export const PrintLabelsModal = atom({
  key: "PrintLabelsModalAtom",
  default: {
    open: false,
    item: {} as any | null,
    forceRef: false,
  },
});

export const PrintLabelsReceptionModal = () => {
  const [printLabelsModals, setPrintLabelsModal] =
    useRecoilState(PrintLabelsModal);
  const [nbrEtiq, setNbrEtiq] = useState(1);

  const { loading } = useReception();
  const [printLoading, setPrintLoading] = useState(false);

  const handleClose = () => {
    setNbrEtiq(1);
    setPrintLabelsModal({ open: false, item: null, forceRef: false });
  };

  useControlledEffect(() => {
    if (printLabelsModals.open) {
      if (printLabelsModals.item?.qteRecu)
        setNbrEtiq(printLabelsModals.item?.qteRecu || 1);
      setTimeout(() => {
        focusInput("#nbr-etiq");
      }, 250);
    }
  }, [printLabelsModals.open]);

  return (
    <Modal
      className="sm:max-w-[450px]"
      open={printLabelsModals.open}
      loading={printLoading}
      onClose={() => handleClose()}
    >
      <ModalContent title={"Impression d'étiquettes"}>
        <div className="flex flex-col gap-2">
          <Info>
            {`${
              printLabelsModals.item?.desig
                ? printLabelsModals.item?.desig
                : printLabelsModals.item?.designation
            }`}{" "}
          </Info>
          <Info>{`${printLabelsModals.item?.ean13}`} </Info>
          <div className="flex items-center gap-2 my-2">
            <InputCounter
              id={"nbr-etiq"}
              size="md"
              min={1}
              value={nbrEtiq}
              onChange={(value) => {
                if (value < 1) {
                  setNbrEtiq(1);
                  return;
                }
                setNbrEtiq(value);
              }}
            />
            <Button
              disabled={loading}
              shortcut={["enter"]}
              onClick={async () => {
                try {
                  if (printLabelsModals.item) {
                    setPrintLoading(true);
                    if (printLabelsModals.forceRef) {
                      await ProductsApiClient.referenceProduct(
                        printLabelsModals.item.id
                      );
                    }
                    await ProductsApiClient.productLabel([
                      {
                        ean13: printLabelsModals.item.ean13,
                        nbrEtiq: nbrEtiq,
                      },
                    ]);
                    setPrintLoading(false);
                  }
                  handleClose();
                } catch {
                  setPrintLoading(false);
                }
              }}
            >
              Imprimer
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
