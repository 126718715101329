import { toDateISOFr } from "@features/utils/format/dates";
import { Column } from "@molecules/table/table";

const LIVR_REAS_COLUMNS = [
  {
    title: "Rayon",
    id: "code_rayon",
    searchIds: ["codeRayon"],
    orderable: true,
    render: (p) => p.libRayon,
  },
  {
    title: "Vendeur",
    id: "logi",
    searchIds: ["logi"],
    orderable: true,
    render: (p) => p.logi,
  },
  {
    title: "Fournisseur",
    id: "code_fourn_habit",
    searchIds: ["codeFournHabit"],
    orderable: true,
    render: (p) => p.codeFournHabit, // À vérifier
  },
  {
    title: "Mouvement",
    id: "lib_type_mvt",
    searchIds: ["libTypeMvt"],
    orderable: true,
    render: (p) => p.libTypeMvt,
  },
  {
    title: "Stock",
    orderable: true,
    id: "qte_stock",
    searchIds: ["qteStock"],
    type: "number",
    //render: (p) => <Base className="w-full text-end">p.qteStock,
    render: (p) => p.qteStock,
  },
  {
    title: "Auteur",
    id: "auteu",
    searchIds: ["auteu"],
    orderable: true,
    render: (p) => p.auteur,
  },
  {
    title: "Auteur-Compositeur",
    id: "auteurCompositeur",
    searchIds: ["auteurCompositeur"],
    orderable: true,
    render: (p) => p.auteurCompositeur,
  },
  {
    title: "Éditeur",
    id: "code_edit",
    searchIds: ["codeEdit"],
    orderable: true,
    render: (p) => p.codeEdit,
  },
  {
    title: "Fabricant",
    id: "code_fab",
    searchIds: ["codeFab"],
    orderable: true,
    render: (p) => p.codeFab,
  },

  {
    title: "Marque",
    id: "code_marque",
    searchIds: ["codeMarque"],
    orderable: true,
    render: (p) => p.codeMarque, // À vérifier
  },
  {
    title: "Promo",
    id: "prod_lieu_canal_vente_code_promo",
    searchIds: ["codePromo"],
    orderable: true,
    render: (p) => p.codePromo, // À vérifier
  },
  {
    title: "Departement",
    id: "code_dept",
    searchIds: ["codeDept"],
    orderable: true,
    render: (p) => p.codeDept,
  },
  {
    title: "Marge",
    id: "marge",
    searchIds: ["marge"],
    orderable: true,
    type: "percentage",
    render: (p) => `${p.marge.toFixed(2)}%`,
  },
  {
    title: "Couv.",
    id: "delai_stock",
    orderable: true, // Mettre id
    searchIds: ["delaiStock"],
    render: (p) => p.delaiStock,
  },
  {
    title: "Vente",
    id: "vente", //Vérifier sauvegarde modif tab
    searchIds: ["vente"],
    orderable: true,
    type: "number",
    render: (p) => p.vente,
  },
  {
    title: "Vente jour",
    id: "qte_vente_jour",
    searchIds: ["qteVenteJour"],
    orderable: true,
    type: "number",
    render: (p) => p.qteVenteJour,
  },
  {
    title: "Prix Vente",
    id: "prix_vente_HT",
    searchIds: ["prixVenteHT"],
    orderable: true,
    type: "price",
    render: (p) => p.prixVenteHT,
  },
  {
    title: "Première entrée",
    id: "date_prem_entre",
    searchIds: ["datePremEntre"],
    type: "date",
    orderable: true,
    render: (p) => toDateISOFr(p.datePremEntre),
  },
  {
    title: "Diffuseur",
    id: "code_diffu",
    searchIds: ["codeDiffu"],
    orderable: true,
    render: (p) => p.codeDiffu,
  },
  {
    title: "CodeColl", // Titre et clé à vérifier
    id: "codeColl",
    searchIds: ["codeColl"],
    orderable: true,
    render: (p) => p.codeColl,
  },
  {
    title: "État",
    id: "code_etat",
    searchIds: ["codeEtat"],
    orderable: true,
    render: (p) => p.codeEtat,
  },
  {
    title: "Support",
    id: "lib_supp",
    searchIds: ["support"],
    orderable: true,
    render: (p) => p.support,
  },
  {
    title: "Genre",
    id: "lib_genre",
    searchIds: ["genre"],
    orderable: true,
    render: (p) => p.genre,
  },
] as Column<any>[];

export const getReassortTabColumns = (modeGestion: string) => {
  switch (modeGestion) {
    case "L":
      return LIVR_REAS_COLUMNS;
    case "D":
      return LIVR_REAS_COLUMNS;
    case "P":
      return [
        ...LIVR_REAS_COLUMNS,
        {
          title: "Famille",
          id: "lib_famil",
          searchIds: ["famille"],
          orderable: true,
          render: (p: any) => p.famille,
        },
        {
          title: "Sous-famille",
          id: "lib_sous_famil",
          searchIds: ["sousFamille"],
          orderable: true,
          render: (p: any) => p.sousFamille,
        },
      ];
    default:
      return LIVR_REAS_COLUMNS;
  }
};
