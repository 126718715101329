import { BookRender } from "@atoms/book";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, Info, InfoSmall } from "@atoms/text";
import { ROUTES } from "@features/routes";
import { formatAmount } from "@features/utils/format/strings";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { useNavigate } from "react-router-dom";
import { atom, useRecoilState } from "recoil";

export const ReceptionProductListModalAtom = atom({
  key: "ReceptionProductListModalAtom",
  default: false,
});

export const ReceptionProductListModal = () => {
  const [receptionProductListModal, setReceptionProductListModal] =
    useRecoilState(ReceptionProductListModalAtom);

  const navigate = useNavigate();

  const allColumns: Column<any>[] = [
    {
      title: "Article",
      orderable: true,
      render: (orderItem, { responsive }) =>
        responsive ? (
          <div
            className="text-right"
            onClick={() =>
              navigate(
                ROUTES.Product.replace(/:ean/, orderItem.product.id).replace(
                  /:type/,
                  orderItem.product.codeTypeProd
                )
              )
            }
          >
            <Base data-tooltip={orderItem.product.designation}>
              {orderItem.product.designation}
            </Base>
            <br />
            <Info>{orderItem.product.id}</Info>
          </div>
        ) : (
          <div
            className="flex flex-row items-center space-x-4 cursor-pointer"
            onClick={() =>
              navigate(
                ROUTES.Product.replace(/:ean/, orderItem.product.id).replace(
                  /:type/,
                  orderItem.product.codeTypeProd
                )
              )
            }
          >
            {orderItem.product.imageURL && (
              <div className="w-10 print:hidden">
                <BookRender src={orderItem.product.imageURL} />
              </div>
            )}
            <div className="flex flex-col">
              <Base
                className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal max-w-xs"
                data-tooltip={orderItem.product.designation}
              >
                {orderItem.product.designation}
              </Base>
              <Info>{orderItem.product.id}</Info>
              <InfoSmall className="print:hidden">
                {orderItem.product.qteDispoVenteFutur} disponible à la vente
                future ({orderItem.product.qteDispoVente} disponible à la vente,
                {orderItem.product.qtePrepCdePlusTrans} attendue en commande et
                transfert (dont {orderItem.product.qteAffecCli} réservée
                clients))
              </InfoSmall>
            </div>
          </div>
        ),
    },
    {
      title: "Quant.",
      orderable: true,
      type: "number",
      render: (orderItem) => orderItem.qte,
    },
    {
      title: "Prix TTC Achat Unit € (Total: xx.xx€)",
      orderable: true,
      type: "price",
      render: (orderItem) => formatAmount(orderItem.pxUnit),
    },
    {
      title: "Prix de vente TTC € (Total: xx.xx€)",
      orderable: true,
      type: "price",
      render: (orderItem) => formatAmount(orderItem.pxUnit),
    },
  ];

  return (
    <Modal
      className="bg-red-400 sm:max-w-[900px]"
      open={receptionProductListModal}
      onClose={() => setReceptionProductListModal(false)}
    >
      <ModalContent title={"Historique commandes"}>
        <div className="grow">
          <Table
            data={["Oui", "Non"]}
            showPagination={false}
            columns={allColumns}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};
