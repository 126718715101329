import { Frame } from "@atoms/layout/frame";
import { Page } from "@atoms/layout/page";
import { Subtitle } from "@atoms/text";
import { ClientSelection } from "@components/client/select-client";
import { OrderHistory } from "@components/order/history";
import { useCustomer } from "@features/customer/state/use-customer";
import {} from "@features/customer/type";

export const ClientInfosPage = () => {
  const { selectedCustomer } = useCustomer();

  return (
    <Page>
      <Subtitle className="mb-4">
        Historique des commandes de : {selectedCustomer.prnomCli}
        {selectedCustomer.nomCli}
      </Subtitle>
      <div className="flex flex-col sm:flex-row justify-start h-full w-full gap-4 mt-3">
        <Frame className="md:w-1/4">
          <ClientSelection
            client={selectedCustomer}
            readonly={true}
            onChange={() => {}}
            defined={true}
            goToClientSearchPage={true}
          />
        </Frame>
        {selectedCustomer.numCli && (
          <Frame className="grow">
            <OrderHistory
              clientID={selectedCustomer.numCli || null}
              clientMail={selectedCustomer.addrMail}
              clientPhone={selectedCustomer.mobil}
            />
          </Frame>
        )}
      </div>
    </Page>
  );
};
