import { Base } from "@atoms/text";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarIcon,
} from "@heroicons/react/outline";
import { useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { defaultInputClassName, errorInputClassName } from "./input-text";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import fr from "date-fns/locale/fr";
import CustomDatePicker from "./custom-datepicker";

registerLocale("fr", fr);

const el = document.createElement("div");
el.id = "calendar-picker-portal";
el.style.zIndex = "99";
el.style.position = "relative";
document.body.appendChild(el);

interface InputProps
  extends Omit<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    "size" | "onChange" | "value"
  > {
  highlight?: boolean;
  theme?: "plain";
  hasError?: boolean;
  size?: "md" | "lg" | "sm";
  className?: string;
  children?: React.ReactNode;
  value?: string | Date | null;
  placeholder?: string;
  onChange?: (e: Date | null) => void;
}

export function InputDate(props: InputProps) {
  const [active, setActive] = useState(false);
  const { t } = useTranslation();

  // Typage de ref correct : CustomDatePicker renvoie un DatePicker
  const datePickerRef = useRef<ReactDatePicker | null>(null);

  let inputClassName = props.hasError
    ? errorInputClassName(props.theme)
    : defaultInputClassName(props.theme);
  inputClassName = inputClassName + (props.disabled ? " opacity-75" : "");

  if (props.highlight && props.value)
    inputClassName = inputClassName + " !ring-2 !ring-yellow-500";

  if (props.size === "lg") inputClassName = inputClassName + " text-base h-11";
  else if (props.size === "sm")
    inputClassName = inputClassName + " text-sm h-7 py-0 px-3";
  else inputClassName = inputClassName + " text-sm h-9 py-1";

  return (
    <span className="relative">
      <CustomDatePicker
        portalId="calendar-picker-portal"
        ref={datePickerRef}
        wrapperClassName={`
          ${
            props.style?.maxWidth
              ? `max-w-[${props.style.maxWidth}px]`
              : "w-full"
          }  
          ${active ? "z-10" : ""}
        `}
        //dateFormat={"yyyy-MM-dd"}
        dateFormat={"dd/MM/yyyy"}
        placeholderText={props.placeholder || "JJ/MM/AAAA"}
        className={inputClassName + " " + props.className}
        selected={props.value ? new Date(props.value) : null}
        onChange={(date: Date | null) => props.onChange?.(date)}
        isClearable
        onFocus={() => setActive(true)}
        onBlur={(e: any) => {
          props.onBlur && props.onBlur(e);
          setActive(false);
        }}
        locale="fr"
        clearButtonClassName="mr-1"
        calendarClassName="select-none !bg-white dark:!bg-slate-900 shadow border !border-slate-300 dark:!border-slate-700  overflow-hidden"
        renderCustomHeader={(e) => (
          <div className="flex flex-row items-center">
            <div
              className="cursor-pointer hover:text-blue-600 text-black dark:text-white"
              onClick={e.decreaseMonth}
            >
              <ArrowLeftIcon className="h-4 w-4 mx-3" />
            </div>
            <Base className="block grow">
              {t("general.months").split(",")[new Date(e.monthDate).getMonth()]}{" "}
              {new Date(e.monthDate).getFullYear()}
            </Base>
            <div
              className="cursor-pointer hover:text-blue-600 text-black dark:text-white"
              onClick={e.increaseMonth}
            >
              <ArrowRightIcon className="h-4 w-4 mx-3" />
            </div>
          </div>
        )}
      />
      {!props.value && (
        <div className="absolute w-5 flex flex-col justify-center inset-y-0 right-1">
          <CalendarIcon
            onClick={() => {
              if (datePickerRef.current && datePickerRef.current.setFocus) {
                datePickerRef.current.setFocus();
                setActive(true);
              }
            }}
            className="h-5 w-5 text-slate-500 opacity-50 cursor-pointer"
          />
        </div>
      )}
    </span>
  );
}

export default InputDate;
