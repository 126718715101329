import { Button } from "@atoms/button/button";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { BaseBold, BaseSmall, Info, InfoSmallBold } from "@atoms/text";
import { useReception } from "@features/reception/state/use-reception";
import { MissingArticle } from "@features/reception/types";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { atom, useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import Select from "@atoms/input/input-select";
import { ProductAvailability } from "@features/general/common/types";
import toast from "react-hot-toast";
import { CommandClientModalAtom } from "../commande-client-modal";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { ChevronDoubleDownIcon } from "@heroicons/react/solid";
import { Frame } from "@atoms/layout/frame";

type MissingArticlesModalType = {
  open: boolean;
  missingArticles: MissingArticle[];
  motifs: ProductAvailability[];
};

export const MissingArticlesModalAtom = atom<MissingArticlesModalType>({
  key: "MissingArticlesModal",
  default: { open: false, missingArticles: [], motifs: [] },
});

export const MissingArticlesModal = () => {
  const [missingArticlesModal, setMissingArticlesModal] = useRecoilState(
    MissingArticlesModalAtom
  );
  const { updateMissingArticles, getMissingCliOrders } = useReception();
  const { current: currentLocation } = useShopLocations();
  const [selectedRows, setSelectedRows] = useState<MissingArticle[]>([]);
  const [blurOn, setBlurOn] = useState(true);
  const [commandClientModal, setCommandClientModal] = useRecoilState(
    CommandClientModalAtom
  );

  const handleChange = async (
    e: any,
    articles: MissingArticle[],
    field: string
  ) => {
    const saveTab = [...missingArticlesModal.missingArticles];
    let updatedArticles: MissingArticle[] = [];

    setMissingArticlesModal((prevState) => {
      updatedArticles = prevState.missingArticles.map((article) =>
        articles.some((r) => article.ean13 === r.ean13)
          ? { ...article, [field]: e.target.value }
          : article
      );
      return {
        ...prevState,
        missingArticles: updatedArticles,
      };
    });

    const res = await updateMissingArticles(updatedArticles);

    if (res.every((line) => line.success)) {
      toast.success("Modification sauvegardée.");
      if (!res.every((line) => line.errorMsg === "")) {
        res.map(
          (line) =>
            line.errorMsg !== "" &&
            toast("⚠️ " + line.errorMsg, {
              duration: 5000,
            })
        );
      }
    } else {
      setMissingArticlesModal({
        ...missingArticlesModal,
        missingArticles: saveTab,
      });
      res.map((line) => toast.error(line.errorMsg));
    }
  };

  useEffect(() => {
    setBlurOn(!commandClientModal.open);
  }, [commandClientModal]);

  const numCde: Column<MissingArticle> = {
    id: "numCde",
    title: "Num. commande",
    render: (el) => (
      <BaseSmall className="w-full text-center">{el.numCde}</BaseSmall>
    ), //Temp
  };

  const articles: Column<MissingArticle> = {
    id: "ean13",
    searchIds: ["ean13", "designation", "idBD", "referProd"],
    title: "Articles",
    orderable: true,
    render: (p) => (
      <div className="grow flex flex-col items-start verflow-hidden border-r border-r-1 mr-1">
        <InfoSmallBold
          className="overflow-hidden cursor-text text-ellipsis print:whitespace-normal"
          data-tooltip={p.designation}
        >
          {p.designation}
        </InfoSmallBold>
        <Info>{p.ean13}</Info>
        {p.idBD !== "" && <Info>ID BD: {p.idBD}</Info>}
        {p.referProd !== "" && <Info>Réferénce: {p.referProd}</Info>}
      </div>
    ),
  };

  const qteStock: Column<MissingArticle> = {
    id: "qteStock",
    title: "Qte stock",
    type: "number",
    render: (el) => el.qteStock,
  };

  const qteCde: Column<MissingArticle> = {
    id: "qteCde",
    title: "Qte commandée",
    type: "number",
    render: (el) => el.qteCde, // À renseigner
  };

  const qteRecu: Column<MissingArticle> = {
    id: "qteRecu",
    title: "Qte reçue",
    type: "number",
    render: (el) => el.qteRecu,
  };

  const motif: Column<MissingArticle> = {
    id: "codeDispo",
    title: "Motif",
    render: (el) => (
      <div className="w-full flex justify-center">
        <Select
          value={el.codeDispo}
          onChange={async (e) => {
            await handleChange(e, [el], "codeDispo");
          }}
        >
          <option value="">-</option>
          {missingArticlesModal.motifs.map((motif) => (
            <option value={motif.code}>{motif.lib}</option>
          ))}
        </Select>
      </div>
    ),
  };

  const action: Column<MissingArticle> = {
    title: "Action",
    render: (el) => (
      <div className="w-full grow flex justify-center z-10">
        <Select
          id={el.ean13}
          className="max-w-36 shrink-0"
          disabled={selectedRows.length > 0}
          value={el.act}
          onChange={async (e) => {
            await handleChange(e, [el], "act");
          }}
        >
          <option value="">Aucune</option>
          <option value="NOTE">Noter </option>
          <option value="RCDE">Re-commander</option>
          <option value="ANNU">Annuler</option>
        </Select>
      </div>
    ),
  };
  const nbCmdClient: Column<MissingArticle> = {
    title: "Commandes client",
    id: "nbCdeCli",
    type: "boolean",
    render: (el) => (
      <div className="w-full flex justify-center">
        <Button
          disabled={el.nbCdeCli === 0}
          theme="secondary"
          onClick={async () => {
            const res = await getMissingCliOrders(
              el.ean13,
              currentLocation!.codeLieu,
              el.numCde
            );
            if (res)
              setCommandClientModal({
                open: true,
                listCde: res,
              });
          }}
        >
          {el.nbCdeCli} Cde client
        </Button>
      </div>
    ),
  };

  const infosFournisseur: Column<MissingArticle> = {
    title: "Infos fournisseur",
    render: (el) => (
      <BaseSmall className="w-full text-center">
        {el.actFourn ? "Noté" : "Non noté"}
        {el.libDispoFourn !== "" ? `-${el.libDispoFourn}` : ""}
      </BaseSmall>
    ),
  };

  const handleClose = () => {
    setMissingArticlesModal({ open: false, missingArticles: [], motifs: [] });
  };

  return (
    <Modal
      style={{ maxWidth: "1600px" }}
      open={missingArticlesModal.open}
      onClose={() => handleClose()}
      blurActivated={blurOn}
    >
      <ModalContent title={"Produits manquants"}>
        <Frame
          className={`w-full flex flex-col gap-2 justify-start mb-8 mt-2 bg-lime-400 ${
            selectedRows.length === 0 ? "opacity-60 cursor-not-allowed" : ""
          }`}
        >
          <BaseBold className="flex flex-col justify-center">
            Mise à jour groupée
          </BaseBold>

          <div className="w-full flex gap-2">
            <div className="flex gap-2">
              <Info className="flex flex-col justify-center">Motif</Info>
              <Select
                className="max-w-36 shrink-0"
                value={""}
                disabled={selectedRows.length === 0}
                onChange={async (e) => {
                  await handleChange(e, selectedRows, "codeDispo");
                }}
              >
                <option value="">-</option>
                {missingArticlesModal.motifs.map((motif) => (
                  <option value={motif.code}>{motif.lib}</option>
                ))}
              </Select>
            </div>

            <div className="flex gap-2">
              <Info className="flex flex-col justify-center">Action</Info>
              <Select
                className="max-w-36 shrink-0"
                value={""}
                disabled={selectedRows.length === 0}
                onChange={async (e) => {
                  await handleChange(e, selectedRows, "act");
                }}
              >
                <option value="">Aucune</option>
                <option value="NOTE">Noter </option>
                <option value="RCDE">Re-commander</option>
                <option value="ANNU">Annuler</option>
              </Select>
            </div>
          </div>
        </Frame>
        <div className="grow">
          {selectedRows.length === 0 ? (
            <Info>
              {`
            ${missingArticlesModal.missingArticles.reduce(
              (acc, product) => acc + product.qteStock,
              0
            )}
             articles en stock (${missingArticlesModal.missingArticles.length}
            références)`}
            </Info>
          ) : (
            <Info>{`${
              selectedRows.length
            } références séléctionnée(s) pour une quantité en stock de ${selectedRows.reduce(
              (acc, product) => acc + product.qteStock,
              0
            )} articles`}</Info>
          )}

          <Table
            emptyTabText={"Aucun articles manquant"}
            searchModeEnabled={true}
            columns={[
              numCde,
              articles,
              qteStock,
              qteCde,
              qteRecu,
              motif,
              action,
              nbCmdClient,
              infosFournisseur,
            ]}
            data={missingArticlesModal.missingArticles}
            rowIndex="ean13"
            onSelect={(e) => setSelectedRows(e)}
          />
        </div>
        <div className="w-full flex flex-row justify-end gap-4 mt-4">
          <Button
            onClick={() => handleClose()}
            icon={({ className }) => (
              <ChevronDoubleDownIcon className={className + ""} />
            )}
          >
            Poursuivre la clôture
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};
