import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { Checkbox } from "@atoms/input/input-checkbox";
import { Frame } from "@atoms/layout/frame";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { InfoSmallBold } from "@atoms/text";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { CommonApiClient } from "@features/general/common/api-client/api-client";
import { ROUTES } from "@features/routes";
import { SupplierApiClient } from "@features/supplier/api-client/api-client";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import {
  OrderPrepBrouillard,
  ShippingMode,
  Supplier,
  SupplierOrderPrepHeader,
} from "@features/supplier/types";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { CheckCircleIcon, PrinterIcon } from "@heroicons/react/outline";
import { LuArrowRightFromLine } from "react-icons/lu";
import { CommonConfirmModalAtom } from "@molecules/confirm-modal";
import { Form } from "@molecules/form";
import { SearchFormFieldType } from "@molecules/form/types";
import { PdfModal } from "@molecules/pdf-viewer/pdf-viewer-modal";
import { useState } from "react";
import toast from "react-hot-toast";
import { FaExclamationTriangle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { SendMultiOrderModal } from "./multi-order-modal";
import { InputLabel } from "@atoms/input/decoration-label";
import { Input } from "@atoms/input/input-text";
import { deepEqual } from "@features/utils/divers";
import { TabVertModalAtom } from "@components/general/tab-vert-modal";
import { GroupeLieu } from "@features/general/common/types";
import { addDays } from "date-fns";
import {
  SendOrderHeaders,
  SendOrderLines,
} from "@features/supplier/state/store";

type SendOrderModalType = {
  open: boolean;
  codeTypeProd: string;
  codeLieuPourQui: string;
  niv: boolean;
  orderBrouillard?: OrderPrepBrouillard;
  orderHeaderList?: SupplierOrderPrepHeader[];
  mode?: string;
};

export const SendOrderModal = atom<SendOrderModalType>({
  key: "SendOrderModalAtom",
  default: { open: false, codeTypeProd: "", codeLieuPourQui: "", niv: false },
});

export const SendSupplierOrderModal = () => {
  const [sendOrderModal, setSendOrderModal] = useRecoilState(SendOrderModal);
  const setTabVertModal = useSetRecoilState(TabVertModalAtom);

  const [originalBroui, setOriginalBroui] = useState<OrderPrepBrouillard>();
  const setSendMultiOrderModal = useSetRecoilState(SendMultiOrderModal);
  const [confirmModal, setConfirmModal] = useRecoilState(
    CommonConfirmModalAtom
  );
  const [waitRank, setWaitRank] = useState(0);
  const setOrderHeaders = useSetRecoilState(SendOrderHeaders);

  const setPdfModal = useSetRecoilState(PdfModal);
  const [finish, setFinish] = useState(false);
  const [, setBrouiList] = useState<OrderPrepBrouillard[]>([]);
  const [numCde, setNumCde] = useState("");
  const [sameBrouil, setSameBrouil] = useState(true);
  const { current } = useShopLocations();
  const setOrderLines = useSetRecoilState(SendOrderLines);

  const navigate = useNavigate();
  const {
    loading,
    selectedPrepHeader,
    getOrderPreparationHeaders,
    changeSelectedPrepHeader,
    getSuppliers,
    createOrderBrouillard,
    getOrderBrouillard,
    updateOrderBrouillard,
    getModesExpedition,
    sendFournOrder,
    getSupplierDocument,
    updateOrderPreparationLineGrp,
    getOrderPreparationLines,
  } = useSuppliers();
  //const [valid, setValid] = useState(false);
  //const [edi, setEdi] = useState(false);
  const [modesExpedition, setModesExpedition] = useState<ShippingMode[] | null>(
    null
  );
  const [tauxTypes, setTauxTypes] = useState<
    { value: string; label: string }[] | null
  >(null);
  const [grpLieu, setGrpLieu] = useState<GroupeLieu[] | null>(null);
  const [typeAchat, setTypeAchat] = useState<
    { value: string; label: string }[] | null
  >(null);
  const [servEdi, setServEdi] = useState<
    { value: string; label: string }[] | null
  >(null);
  const [transmissions, setTransmissions] = useState<
    { value: string; label: string }[] | null
  >(null);
  const [forms, setForms] = useState<{ value: string; label: string }[] | null>(
    null
  );
  const [notations, setNotations] = useState<
    { value: string; label: string }[] | null
  >(null);

  const handleClose = async (redirect: boolean = true) => {
    if (!confirmModal.open) {
      if (
        sendOrderModal.codeTypeProd !== "" &&
        sendOrderModal.orderBrouillard &&
        sendOrderModal.orderHeaderList &&
        !finish // temp
      ) {
        for (let i = 0; i < sendOrderModal.orderHeaderList.length; i++) {
          await SupplierApiClient.processingOrderPrepHeader(
            sendOrderModal.orderHeaderList[i].codeTypeProd,
            sendOrderModal.orderHeaderList[i].codeLieuPourQui,
            sendOrderModal.orderHeaderList[i].niv,
            sendOrderModal.orderHeaderList[i], // temp
            false
          );
        }
      }
      //setValid(false);
      setWaitRank(0);
      setFinish(false);
      setBrouiList([]);
      setOriginalBroui(undefined);
      setSendOrderModal({
        open: false,
        codeTypeProd: "",
        codeLieuPourQui: "",
        niv: false,
        orderBrouillard: undefined,
        orderHeaderList: undefined,
      });
      if (finish && redirect) navigate(ROUTES.SupplierSendOrderPage);
    }
  };

  const areParamsValid = (brouil: OrderPrepBrouillard) => {
    const missingFields = [];

    if (!(brouil.codeFournEnv && brouil.codeFournEnv !== ""))
      missingFields.push('"Fournisseur d\'envoi"');
    if (brouil.edi) {
      if (!(brouil.codeGrp && brouil.codeGrp !== ""))
        missingFields.push('"Code groupe"');
      if (!(brouil.codeForm && brouil.codeForm !== ""))
        missingFields.push('"Format"');
      if (!(brouil.modeTransCde && brouil.modeTransCde !== ""))
        missingFields.push('"Transmission"');
      if (!(brouil.codeNotat && brouil.codeNotat !== ""))
        missingFields.push('"Notation"');
    }
    if (missingFields.length > 0) {
      toast.error(
        `Impossible de valider les paramètres. Le(s) champ(s):  ${missingFields.join(
          ", "
        )} sont vide(s) ou invalide(s).` // Voir pour lister les champs manquants
      );
      return false;
    }
    return true;
  };

  const handleBrouillardModalFournMode = async () => {
    const modeGest = current
      ? current.items?.find(
          (el) => el.codeTypeProd === sendOrderModal.codeTypeProd
        )?.modeGest || "P"
      : "P";
    if (sendOrderModal.orderHeaderList) {
      for (const orderHeader of sendOrderModal.orderHeaderList) {
        try {
          const resCreat = await createOrderBrouillard(
            orderHeader.codeTypeProd,
            orderHeader.codeLieuPourQui,
            orderHeader.niv,
            orderHeader,
            true
          );
          if (resCreat) {
            await SupplierApiClient.processingOrderPrepHeader(
              orderHeader.codeTypeProd,
              orderHeader.codeLieuPourQui,
              orderHeader.niv,
              orderHeader, // temp
              true
            );
          }
        } catch (err) {
          console.error("Erreur lors de la création du brouillard :", err);
        }
      }
      try {
        const resBrouillard = await getOrderBrouillard(
          sendOrderModal.orderHeaderList[0].codeTypeProd,
          sendOrderModal.orderHeaderList[0].codeLieuPourQui,
          sendOrderModal.orderHeaderList[0].niv,
          sendOrderModal.orderHeaderList[0]
        );
        if (resBrouillard) {
          setSendOrderModal({
            ...sendOrderModal,
            orderBrouillard: {
              ...resBrouillard,
              codeLieuEdi: current?.codeLieuEdi,
            },
          });
          setOriginalBroui({
            ...resBrouillard,
            codeLieuEdi: current?.codeLieuEdi,
          });
          try {
            const tempResBrouillard: OrderPrepBrouillard[] = [];
            for (let i = 1; i < sendOrderModal.orderHeaderList.length; i++) {
              const resTemp = await getOrderBrouillard(
                sendOrderModal.orderHeaderList[i].codeTypeProd,
                sendOrderModal.orderHeaderList[i].codeLieuPourQui,
                sendOrderModal.orderHeaderList[i].niv,
                sendOrderModal.orderHeaderList[i]
              );
              if (resTemp)
                tempResBrouillard.push({
                  ...resBrouillard,
                  numBroui: resTemp.numBroui,
                  panie: resTemp.panie,
                  codeModeExpe: resTemp.codeModeExpe,
                  nbrLigne: resTemp.nbrLigne,
                  nbrRef: resTemp.nbrRef,
                  oldCodeModeExpe: resTemp.codeModeExpe,
                });
            }
            await updateOrderBrouillard(
              sendOrderModal.codeTypeProd,
              modeGest,
              sendOrderModal.niv,
              sendOrderModal.codeLieuPourQui,
              current?.codeLieuEdi || "",
              tempResBrouillard
            );
          } catch (err) {
            console.error(
              "Erreur lors de la récupération du brouillard :",
              err
            );
          }
        }
      } catch (err) {
        console.error("Erreur lors de la récupération du brouillard :", err);
      }
    }
  };

  const handleBrouillardModal = async (
    force = false,
    prepHeader: SupplierOrderPrepHeader
  ) => {
    if (sendOrderModal) {
      const res = await createOrderBrouillard(
        sendOrderModal.codeTypeProd,
        sendOrderModal.codeLieuPourQui,
        sendOrderModal.niv,
        prepHeader,
        force
      );
      if (res) {
        if (res.response === "OK") {
          await SupplierApiClient.processingOrderPrepHeader(
            sendOrderModal.codeTypeProd,
            sendOrderModal.codeLieuPourQui,
            sendOrderModal.niv,
            prepHeader,
            true
          );
          if (res.msg !== "") toast.success(res.msg);
          const resBrouillard = await getOrderBrouillard(
            sendOrderModal.codeTypeProd,
            sendOrderModal.codeLieuPourQui,
            sendOrderModal.niv,
            prepHeader
          );
          if (resBrouillard) {
            setSendOrderModal({
              ...sendOrderModal,
              codeTypeProd: sendOrderModal.codeTypeProd,
              codeLieuPourQui: sendOrderModal.codeLieuPourQui,
              niv: sendOrderModal.niv,
              orderBrouillard: {
                ...resBrouillard,
                codeLieuEdi: current?.codeLieuEdi,
              },
            });
            setOriginalBroui({
              ...resBrouillard,
              codeLieuEdi: current?.codeLieuEdi,
            });
          }
        }
        if (res && res.response === "?") {
          setConfirmModal({
            theme: "warning",
            icon: () => (
              <FaExclamationTriangle className="text-red-600 h-6 w-6" />
            ),
            open: true,
            title: "",
            message: res.msg,
            onAccept: async () => {
              await SupplierApiClient.processingOrderPrepHeader(
                sendOrderModal.codeTypeProd,
                sendOrderModal.codeLieuPourQui,
                sendOrderModal.niv,
                prepHeader,
                false
              );
              handleBrouillardModal(true, prepHeader);
            },
          });
        }
      }
    }
  };

  useControlledEffect(() => {
    const getModeExp = async () => {
      setModesExpedition([...(await getModesExpedition())]);
    };

    const getTauxTypes = async () => {
      const tauxList = await CommonApiClient.getTaux(
        sendOrderModal.codeTypeProd
      );
      setTauxTypes([
        ...tauxList.map((t) => {
          return { label: `${t.lib} (${t.code})`, value: t.code };
        }),
      ]);
    };

    const getGrpLieu = async () => {
      setGrpLieu([...(await CommonApiClient.getGroupeLieu())]);
    };

    const getTypeAchat = async () => {
      setTypeAchat([
        ...(await CommonApiClient.getTypeAchat()).map((el) => {
          return {
            label: `${el.libTypeAchat} (${el.codeTypeAchat})`,
            value: el.codeTypeAchat,
          };
        }),
      ]);
    };

    const getTransmissions = async () => {
      setTransmissions([...(await CommonApiClient.getTransmissions())]);
    };

    const getServsEdi = async () => {
      setServEdi([...(await CommonApiClient.getServEdis())]);
    };

    const getForms = async () => {
      setForms([...(await CommonApiClient.getForms())]);
    };

    const getNotations = async () => {
      setNotations([...(await CommonApiClient.getNotations())]);
    };

    if (sendOrderModal.open) {
      if (modesExpedition?.length === 0 || !modesExpedition) getModeExp();
      if (tauxTypes?.length === 0 || !tauxTypes) getTauxTypes();
      if (grpLieu?.length === 0 || !grpLieu) getGrpLieu();
      if (typeAchat?.length === 0 || !typeAchat) getTypeAchat();
      if (transmissions?.length === 0 || !transmissions) getTransmissions();
      if (servEdi?.length === 0 || !servEdi) getServsEdi();
      if (forms?.length === 0 || !forms) getForms();
      if (notations?.length === 0 || !forms) getNotations();
    }
  }, [sendOrderModal.open]);

  useControlledEffect(() => {
    const getBrouillard = async (prepHeader: SupplierOrderPrepHeader) => {
      handleBrouillardModal(false, prepHeader);
    };
    if (
      sendOrderModal.open &&
      sendOrderModal.orderHeaderList &&
      sendOrderModal.orderHeaderList.length > 0 &&
      (sendOrderModal.mode === "" || !sendOrderModal.mode)
    ) {
      getBrouillard(sendOrderModal.orderHeaderList[waitRank]);
    }
    if (sendOrderModal.mode && sendOrderModal.mode === "envFourn") {
      handleBrouillardModalFournMode();
    }
  }, [sendOrderModal.open, waitRank]);

  useControlledEffect(() => {
    if (sendOrderModal.open && sendOrderModal.orderBrouillard && originalBroui)
      setSameBrouil(deepEqual(sendOrderModal.orderBrouillard, originalBroui));
  }, [sendOrderModal.orderBrouillard, originalBroui]);

  const mainFormPart: SearchFormFieldType[] = [
    {
      key: "codeFourn",
      label: "Fournisseur",
      type: "text",
      alwaysVisible: true,
      edit: true,
      disabled: true,
    },
    {
      key: "codeLieuLivr",
      label: "Livré à",
      type: "text",
      alwaysVisible: true,
      edit: true,
      disabled: true,
    },
    {
      key:
        sendOrderModal.mode === "" || !sendOrderModal.mode
          ? "codeModeExpe"
          : "",
      label: "Exp.",
      type: "select",
      alwaysVisible: true,
      edit: true,
      options: modesExpedition
        ? [
            { label: "<Aucun>", value: "" },
            ...modesExpedition.map((el) => {
              return { label: `${el.lib} (${el.code})`, value: el.code };
            }),
          ]
        : [],
      disabled:
        sendOrderModal.orderHeaderList &&
        sendOrderModal.orderHeaderList.length > 1,
    },
    {
      key: sendOrderModal.mode === "" || !sendOrderModal.mode ? "panie" : "",
      label: "Panier",
      type: "text",
      alwaysVisible: true,
      edit: true,
      disabled: true,
    },
    {
      key: "referOpe",
      label: "Référ. opé",
      type: "text",
      alwaysVisible: true,
      edit: true,
    },
    {
      key: "codeFournEnv",
      label: "Fournisseur d'envoi",
      type: "tab",
      //disabled: sendOrderModal.mode === "envFourn",
      alwaysVisible: true,
      edit: true,
      onClick: async () => {
        setTabVertModal({
          open: true,
          title: "Liste des fournisseurs",
          columns: [
            {
              title: "Fournisseur",
              id: "codeFourn",
              render: (p) => p.codeFourn,
              orderable: true,
            },
            {
              title: "Raison-sociale",
              id: "raisSocial",
              render: (p) => p.raisSocial,
              orderable: true,
            },
            {
              title: "Code grp",
              id: "codeGrpGest",
              render: (p) => p.codeGrpGest,
              orderable: true,
            },
            {
              title: "EDI",
              id: "edi",
              render: (p) => (
                <div className="w-full flex justify-center">
                  <Checkbox disabled={true} value={p.edi} />
                </div>
              ),
              noSearch: true,
            },
            {
              title: "Serveur EDI",
              id: "nomServEdi",
              render: (p) => p.nomServEdi,
              orderable: true,
            },
            {
              title: "Transmission",
              id: "modeTransCde",
              render: (p) => p.modeTransCde,
              orderable: true,
            },
            {
              title: "Reliquat",
              id: "gestReliq",
              render: (p) => (
                <div className="w-full flex justify-center">
                  <Checkbox disabled={true} value={p.gestReliq} />
                </div>
              ),
              noSearch: true,
            },
            {
              title: "tel.",
              id: "tel",
              render: (p) => p.tel,
              orderable: true,
            },
            // {
            //   title: "Compte",
            //   id: "compte",
            //   render: (p) => p.compte,
            // },
            {
              title: "GENCODE",
              id: "gencod",
              render: (p) => p.gencod,
              orderable: true,
            },
            {
              title: "Addr 1",
              id: "addrLigne1",
              render: (p) => p.addrLigne1,
              orderable: true,
            },
            {
              title: "Addr 2",
              id: "addrLigne2",
              render: (p) => p.addrLigne2,
              orderable: true,
            },
            {
              title: "Code Postal",
              id: "addrCp",
              render: (p) => p.addrCp,
              orderable: true,
            },
            {
              title: "Ville",
              id: "addrVille",
              render: (p) => p.addrVille,
              orderable: true,
            },
            {
              title: "Pays",
              id: "addrPays",
              render: (p) => p.addrPays,
              orderable: true,
            },
          ],
          onTabClick: (el: Supplier) => {
            if (sendOrderModal.orderBrouillard) {
              let tempBroui = { ...sendOrderModal.orderBrouillard };
              tempBroui.codeFournEnv = el.codeFourn;
              if (!el.edi) {
                tempBroui = {
                  ...sendOrderModal.orderBrouillard,
                  edi: false,
                  codeGrp: "",
                  nomServEdi: "",
                  modeTransCde: "",
                  codeForm: "",
                  fax: "",
                  gencodFourn: el.gencod,
                };
              } else {
                tempBroui = {
                  ...sendOrderModal.orderBrouillard,
                  edi: true,
                  codeGrp: el.codeGrp,
                  nomServEdi: el.nomServEdi,
                  fax: el.fax,
                  addrMail: el.addrMail,
                  modeTransCde: el.modeTransCde,
                  codeForm: el.codeForm,
                  gencodFourn: el.gencod,
                  codeNotat: el.codeNotat,
                };
              }
              setSendOrderModal({
                ...sendOrderModal,
                orderBrouillard: sendOrderModal.orderBrouillard
                  ? {
                      ...tempBroui,
                      codeFournEnv: el.codeFourn,
                      codeLieuEdi: current?.codeLieuEdi,
                    }
                  : undefined,
              });
            }
          },
          getData: async (
            page: number,
            perPage: number,
            order: "ASC" | "DESC" | undefined,
            orderBy: number | undefined,
            filters: any
          ) => {
            const requiredFilters = {
              codeTypeProd: sendOrderModal.codeTypeProd,
              codeLieuLivr: sendOrderModal.codeLieuPourQui,
              bloq: false,
            };
            return await getSuppliers(
              {
                orderDir: order,
                pageNumber: page,
                pageSize: perPage,
              },
              { ...requiredFilters, ...filters }
            );
          },
        });
      },
    },

    {
      key: "codeTypeTaux",
      label: "TAUM",
      type: "select",
      alwaysVisible: true,
      edit: true,
      options: tauxTypes || [],
      disabled: true,
    },
    {
      key: "codeTypeAchat",
      label: "Type achat",
      type: "select",
      alwaysVisible: true,
      edit: true,
      options: typeAchat || [],
      disabled: true,
    },
    {
      key: "codeNotat",
      label: "Notation",
      type: "select",
      alwaysVisible: true,
      edit: true,
      options: notations || [],
      disabled:
        sendOrderModal.orderBrouillard && !sendOrderModal.orderBrouillard.edi,
    },
    {
      key: "dateLivrPrev",
      label: "Livraison prév. ",
      type: "date",
      alwaysVisible: true,
      edit: true,
    },
    {
      key: "refer",
      label: "Référence",
      type: "text",
      alwaysVisible: true,
      edit: true,
    },
    // {
    //   key: "edi",
    //   label: "EDI",
    //   type: "boolean",
    //   alwaysVisible: true,
    //   edit: true,
    // },
    // {
    //   key: "comm",
    //   label: "Commentaire",
    //   type: "html",
    //   alwaysVisible: true,
    //   edit: true,
    // },
  ];

  const ediFormPart: SearchFormFieldType[] = [
    {
      key: "codeFournEnv",
      label: "Fournisseur",
      type: "text",
      alwaysVisible: true,
      disabled: true,
    },
    {
      key: "codeLieuEdi", //Code lieu EDI
      label: "Lieu émission",
      //type: "select",
      alwaysVisible: true,
      edit: true,
      disabled: true,
    },
    {
      key: "codeGrp",
      label: "Groupe lieu EDI",
      type: "tab",
      alwaysVisible: true,
      edit: true,
      render: () => {
        let txt = "Non renseigné";
        grpLieu &&
          grpLieu?.forEach((grp) => {
            if (grp.codeGroupe === sendOrderModal.orderBrouillard?.codeGrp)
              txt = `${grp.libGrp} (${grp.codeGroupe})`;
          });
        return txt;
      },
      onClick: async () => {
        setTabVertModal({
          open: true,
          title: "Liste des groupes EDI",
          columns: [
            {
              title: "Code",
              id: "codeGroupe",
              render: (p) => p.codeGroupe,
              //orderable: true,
            },
            {
              title: "Libellé",
              id: "codeFourn",
              render: (p) => p.libGrp,
              //orderable: true,
            },
            {
              title: "GENCOD émis",
              id: "codeFourn",
              render: (p) => p.gencodEmis,
              //orderable: true,
            },
            {
              title: "GENCOD livré",
              id: "codeFourn",
              render: (p) => p.gencodLivr,
              //orderable: true,
            },
            {
              title: "GENCOD facturé",
              id: "codeFourn",
              render: (p) => p.gencodFact,
              //orderable: true,
            },
            {
              title: "GENCOD payeur",
              id: "codeFourn",
              render: (p) => p.gencodPaie,
              //orderable: true,
            },
          ],
          onTabClick: (el: GroupeLieu) => {
            if (sendOrderModal.orderBrouillard) {
              setSendOrderModal({
                ...sendOrderModal,
                orderBrouillard: sendOrderModal.orderBrouillard
                  ? {
                      ...sendOrderModal.orderBrouillard,
                      codeGrp: el.codeGroupe,
                      codeLieuEdi: current?.codeLieuEdi,
                    }
                  : undefined,
              });
            }
          },
          getData: async () => {
            if (grpLieu)
              return {
                items: grpLieu,
                pageNumber: 1,
                pageSize: grpLieu.length,
                pageCount: 1,
                totalRows: grpLieu.length,
                rowsReturned: grpLieu.length,
              };
            else
              return {
                items: [grpLieu],
                pageNumber: 1,
                pageSize: 0,
                pageCount: 1,
                totalRows: 0,
                rowsReturned: 0,
              };
          },
        });
      },

      //options: grpLieu || [],
    },
    {
      key: "statu", // --> Comment le mapper ??
      label: "Statut",
      type: "select",
      alwaysVisible: true,
      edit: true,
      options: [
        { label: "À envoyer", value: "AVE" },
        { label: "En attente", value: "ATE" },
      ],
      disabled: true,
    },

    {
      key: "nomServEdi",
      label: "Serveur",
      type: "text",
      alwaysVisible: true,
      edit: true,
      options: servEdi || [],
      disabled: true,
    },
    {
      key: "modeTransCde",
      label: "Transmission",
      type: "text",
      alwaysVisible: true,
      edit: true,
      options: transmissions || [],
      disabled: true,
    },
    {
      key: "codeForm",
      label: "Format",
      type: "select",
      alwaysVisible: true,
      edit: true,
      options: forms || [],
      disabled: true,
    },
    {
      key: "fax",
      label: "Fax",
      type: "text",
      alwaysVisible: true,
      edit: true,
      disabled: true,
    },
    {
      key: "addrMail",
      label: "E-mail",
      type: "text",
      alwaysVisible: true,
      edit: true,
      disabled: true,
    },
    {
      key: "gencodFourn",
      label: "GENCOD fourn.",
      type: "text",
      alwaysVisible: true,
      edit: true,
      disabled: true,
    },
    {
      key: "gencodEmis",
      label: "GENCOD emis",
      type: "text",
      alwaysVisible: true,
      edit: true,
      disabled: true,
    },
    {
      key: "gencodLivr",
      label: "GENCOD livré",
      type: "text",
      alwaysVisible: true,
      edit: true,
      disabled: true,
    },
  ];

  return (
    <Modal
      className="sm:max-w-[900px]"
      open={sendOrderModal.open}
      onClose={async () => await handleClose()}
      loading={
        loading ||
        !modesExpedition ||
        !tauxTypes ||
        !grpLieu ||
        !transmissions ||
        !forms
      }
    >
      <ModalContent title={"Paramètres d'envoi de commande(s)"}>
        {sendOrderModal.orderBrouillard &&
          modesExpedition &&
          tauxTypes &&
          typeAchat && (
            <div className="flex flex-col gap-4 md:gap-2">
              <Form
                readonly={false} // Temporaire --> évoluera ultérieurement
                nbColumns={3}
                gapValue={4}
                fields={mainFormPart}
                value={sendOrderModal.orderBrouillard}
                onChange={(newValue) => {
                  if (
                    newValue.codeModeExpe !==
                    sendOrderModal.orderBrouillard?.codeModeExpe
                  ) {
                    const newModeExpe = modesExpedition.find(
                      (m) => m.code === newValue.codeModeExpe
                    );
                    setSendOrderModal({
                      ...sendOrderModal,
                      orderBrouillard: {
                        ...(newValue as OrderPrepBrouillard),
                        codeNotat: newModeExpe!.codeNotat,
                        codeTypeCde: newModeExpe!.codeTypeCde,
                        dateLivrPrev: addDays(
                          Date.now(),
                          newModeExpe!.delaiLivr
                        ),
                      },
                    });
                    return;
                  }
                  setSendOrderModal({
                    ...sendOrderModal,
                    orderBrouillard: {
                      ...(newValue as OrderPrepBrouillard),
                      codeLieuEdi: current?.codeLieuEdi,
                    },
                  });
                }}
              />
              <InputLabel
                label={"Commentaire"}
                input={
                  <Input
                    value={sendOrderModal.orderBrouillard.comm}
                    multiline={true}
                    onChange={(e) =>
                      setSendOrderModal({
                        ...sendOrderModal,
                        orderBrouillard: sendOrderModal.orderBrouillard
                          ? {
                              ...sendOrderModal.orderBrouillard,
                              comm: e.target.value,
                              codeLieuEdi: current?.codeLieuEdi,
                            }
                          : undefined,
                      })
                    }
                  />
                }
              />
              <Checkbox
                //disabled={true}
                value={sendOrderModal.orderBrouillard.edi}
                onChange={async (newValue) => {
                  if (newValue && sendOrderModal.orderBrouillard) {
                    const res = await getSuppliers(undefined, {
                      codeFourn: sendOrderModal.orderBrouillard.codeFournEnv,
                      codeTypeProd: sendOrderModal.codeTypeProd,
                      codeLieuLivr: sendOrderModal.codeLieuPourQui,
                      bloq: false,
                    });
                    if (
                      res &&
                      res.items &&
                      res.items.length > 0 &&
                      sendOrderModal.orderBrouillard
                    ) {
                      const tempBrouil = {
                        ...sendOrderModal.orderBrouillard,
                        edi: true,
                        codeNotat: res.items[0].codeNotat,
                        codeGrp: res.items[0].codeGrp,
                        modeTransCde: res.items[0].modeTransCde,
                        codeForm: res.items[0].codeForm,
                        gencodFourn: res.items[0].gencod,
                        nomServEdi: res.items[0].nomServEdi,
                      };
                      setSendOrderModal({
                        ...sendOrderModal,
                        orderBrouillard: {
                          ...tempBrouil,
                          codeLieuEdi: current?.codeLieuEdi,
                        },
                      });
                      return;
                    }
                  }
                  setSendOrderModal({
                    ...sendOrderModal,
                    orderBrouillard: sendOrderModal.orderBrouillard
                      ? {
                          ...sendOrderModal.orderBrouillard,
                          edi: newValue,
                          nomServEdi: "",
                          modeTransCde: "",
                          codeForm: "",
                          codeLieuEdi: current?.codeLieuEdi,
                          fax: "",
                        }
                      : undefined,
                  });
                }}
                label="EDI"
              />

              {sendOrderModal.orderBrouillard.edi &&
                transmissions &&
                forms &&
                grpLieu && (
                  <Frame>
                    <Form
                      readonly={false} // Temporaire --> évoluera ultérieurement
                      nbColumns={3}
                      gapValue={4}
                      fields={ediFormPart}
                      value={sendOrderModal.orderBrouillard}
                      onChange={(newValue) => {
                        setSendOrderModal({
                          ...sendOrderModal,
                          orderBrouillard: {
                            ...sendOrderModal,
                            ...(newValue as OrderPrepBrouillard),
                            codeLieuEdi: current?.codeLieuEdi,
                          },
                        });
                      }}
                    />
                  </Frame>
                )}
              {/* {!valid && (
              <div className="w-full flex justify-between mt-4">
                <Button
                  theme="danger-outlined"
                  onClick={() => handleClose()}
                  icon={(p) => <XIcon {...p} />}
                >
                  Retour
                </Button>
               
                <Button
                  theme="secondary"
                  onClick={() => {
                    toast("Fonctionnalité bientôt disponible !");
                    setValid(true);
                  }}
                  icon={(p) => <SaveIcon {...p} />}
                >
                  Valider paramètres
                </Button>
              </div>
            )} */}

              <div className="w-full flex justify-end gap-4 relative">
                {!sameBrouil && (
                  <Button
                    onClick={() => {
                      if (originalBroui)
                        setSendOrderModal({
                          ...sendOrderModal,
                          orderBrouillard: {
                            ...originalBroui,
                            codeLieuEdi: current?.codeLieuEdi,
                          },
                        });
                    }}
                    theme="danger"
                  >
                    Annuler modifications
                  </Button>
                )}
                {sameBrouil && (
                  <Button
                    theme="secondary"
                    onClick={async () => {
                      if (sendOrderModal.orderBrouillard) {
                        const shopItemInfo = current?.items?.find(
                          (el) =>
                            el.codeTypeProd === sendOrderModal.codeTypeProd
                        );
                        const res = await getSupplierDocument(
                          finish
                            ? numCde
                            : sendOrderModal.orderBrouillard.numBroui,
                          finish ? "cde" : "brouiCde",
                          shopItemInfo?.modeGest || "L",
                          sendOrderModal.codeTypeProd,
                          sendOrderModal.orderBrouillard.codeLieuParQui,
                          current?.centr || false
                        );
                        if (res) {
                          setPdfModal({
                            open: true,
                            pdfProps: {
                              blobFile: res,
                              iframeSize: {
                                width: "80%",
                                height: "90%",
                              },
                              iFrameClassname:
                                "w-full h-full flex justify-center items-center",
                            },
                            itemList: sendOrderModal.orderHeaderList || [],
                            onNext: async (itemList, index) => {
                              const shopItemInfo = current?.items?.find(
                                (el) =>
                                  el.codeTypeProd ===
                                  sendOrderModal.codeTypeProd
                              );
                              if (res) {
                                const brouil = await getOrderBrouillard(
                                  itemList[index].codeTypeProd,
                                  itemList[index].codeLieuPourQui,
                                  itemList[index].niv,
                                  itemList[index]
                                );
                                if (brouil) {
                                  const resBis = await getSupplierDocument(
                                    brouil.numBroui,
                                    "brouiCde",
                                    shopItemInfo?.modeGest || "P",
                                    itemList[index].codeTypeProd,
                                    current?.codeLieu || "",
                                    current?.centr || false
                                  );
                                  return resBis;
                                }
                              }
                            },
                          });
                        }
                      }
                    }}
                    icon={(p) => <PrinterIcon {...p} />}
                  >
                    {finish
                      ? "Imprimer bon(s) de commande"
                      : "Imprimer brouillard(s)"}
                  </Button>
                )}

                {finish && numCde && numCde.length > 0 && (
                  <Button
                    className="primary"
                    onClick={() => {
                      navigate(
                        ROUTES.SupplierOrderLinePage.replace(
                          /:codeTypeProd/,
                          sendOrderModal.codeTypeProd
                        )
                          .replace(/:codeFourn/, selectedPrepHeader.codeFourn)
                          .replace(/:numCde/, numCde)
                      );
                      handleClose(false);
                    }}
                    icon={(p) => <LuArrowRightFromLine {...p} />}
                  >
                    Accéder à la commande
                  </Button>
                )}

                {!finish &&
                  sameBrouil &&
                  (!sendOrderModal.mode || sendOrderModal.mode === "") && (
                    <ButtonConfirm
                      id="send-order-btn"
                      className="justify-self-end"
                      theme={"valid"}
                      confirmTitle="Valider l'envoi"
                      confirmMessage="Souhaitez-vous confirmer l'envoi de cette commande ?"
                      confirmButtonText="Envoyer"
                      shortcut={["enter"]}
                      onClick={async (e) => {
                        if (sendOrderModal.orderBrouillard?.codeLieuParQui) {
                          const res = await sendFournOrder(
                            sendOrderModal.codeTypeProd,
                            sendOrderModal.codeLieuPourQui,
                            sendOrderModal.niv,
                            sendOrderModal.orderHeaderList
                              ? sendOrderModal.orderHeaderList[waitRank]
                              : selectedPrepHeader,
                            sendOrderModal.orderBrouillard
                          );
                          if (res && sendOrderModal.mode !== "envFourn") {
                            //Dispatch to update header lines in the background page
                            const customEvent = new CustomEvent(
                              "sendOrderEvent",
                              { detail: sendOrderModal.codeTypeProd }
                            );
                            window.dispatchEvent(customEvent);
                            //--------
                            setNumCde(res.numCde);
                            toast.success(
                              "Commande fournisseur crée avec succès."
                            );
                            if (
                              sendOrderModal.orderHeaderList &&
                              sendOrderModal.mode === "global"
                            ) {
                              setWaitRank((previous) => {
                                if (
                                  previous + 1 >
                                  sendOrderModal.orderHeaderList!.length - 1
                                ) {
                                  handleClose();
                                  return previous;
                                }
                                return previous + 1;
                              });
                            } else {
                              setFinish(true);
                            }
                          }
                        }
                        const sendOrderBtn =
                          document.getElementById("send-order-btn");
                        if (sendOrderBtn)
                          (sendOrderBtn as HTMLButtonElement).blur();

                        //handleClose();
                      }}
                      icon={(p) => <CheckCircleIcon {...p} />}
                    >
                      {!sameBrouil
                        ? "Valider les paramètres"
                        : "Envoi de commande"}
                    </ButtonConfirm>
                  )}

                {!finish &&
                  (sendOrderModal.mode === "envFourn" || !sameBrouil) && (
                    <Button
                      id="send-order-btn"
                      className="justify-self-end"
                      theme={!sameBrouil ? "primary" : "valid"}
                      shortcut={["enter"]}
                      onClick={async (e) => {
                        if (!sameBrouil && sendOrderModal.orderBrouillard) {
                          const newModeExpe =
                            sendOrderModal.orderBrouillard.codeModeExpe !==
                            originalBroui?.codeModeExpe;
                          let newHeaderList: any[] = [];
                          if (!areParamsValid(sendOrderModal.orderBrouillard)) {
                            return;
                          }
                          //-------- Fourn part
                          let fournBrouilList: any = [];
                          if (
                            sendOrderModal.mode === "envFourn" &&
                            sendOrderModal.orderHeaderList
                          ) {
                            fournBrouilList = await Promise.all(
                              sendOrderModal.orderHeaderList.map(async (el) => {
                                const brouil = await getOrderBrouillard(
                                  el.codeTypeProd,
                                  el.codeLieuPourQui,
                                  el.niv,
                                  el
                                );
                                const modalB = {
                                  ...sendOrderModal.orderBrouillard,
                                };
                                if (brouil && modalB) {
                                  return {
                                    ...brouil,
                                    edi: modalB.edi,
                                    comm: modalB.comm,
                                    referOpe: modalB.referOpe,
                                    refer: modalB.refer,
                                    //codeModeExpe: modalB.codeModeExpe,
                                    codeFournEnv: modalB.codeFournEnv,
                                    codeTypeAchat: modalB.codeTypeAchat,
                                    codeNotat: modalB.codeNotat,
                                    dateLivrPrev: modalB.dateLivrPrev,
                                  };
                                } else return undefined;
                              })
                            );
                          }
                          //-----,
                          const res = await updateOrderBrouillard(
                            sendOrderModal.codeTypeProd,
                            current
                              ? current.items?.find(
                                  (el) =>
                                    el.codeTypeProd ===
                                    sendOrderModal.codeTypeProd
                                )?.modeGest || "P"
                              : "P",
                            sendOrderModal.niv,
                            sendOrderModal.codeLieuPourQui,
                            current?.codeLieuEdi || "",
                            sendOrderModal.mode === "envFourn"
                              ? [...fournBrouilList]
                              : [
                                  {
                                    ...sendOrderModal.orderBrouillard,
                                  },
                                ]
                          );
                          if (res) {
                            if (res.some((el) => el.response === "KO")) {
                              toast.error(
                                "La mise à jour des paramètres a échoué"
                              );
                              return;
                            }
                            toast.success("Mise à jour des paramètres réussie");
                            //Update les lignes si le codeModeExpe du brouillard change

                            if (newModeExpe && sendOrderModal.orderHeaderList) {
                              await Promise.all(
                                sendOrderModal.orderHeaderList.map(
                                  async (b) => {
                                    if (
                                      sendOrderModal.orderHeaderList &&
                                      sendOrderModal.orderBrouillard
                                    )
                                      await updateOrderPreparationLineGrp(
                                        sendOrderModal.orderHeaderList[0],
                                        sendOrderModal.orderBrouillard
                                          .codeModeExpe,
                                        b.panie
                                      );
                                  }
                                )
                              );
                              newHeaderList =
                                sendOrderModal.orderHeaderList.map((h) => {
                                  if (sendOrderModal.orderBrouillard) {
                                    return {
                                      ...h,
                                      codeModeExpe:
                                        sendOrderModal.orderBrouillard
                                          .codeModeExpe,
                                    };
                                  } else return h;
                                });
                              setSendOrderModal({
                                ...sendOrderModal,
                                orderHeaderList: newHeaderList,
                              });
                              const resLine = await getOrderPreparationLines(
                                sendOrderModal.codeTypeProd || "LIVR",
                                selectedPrepHeader.codeFourn,
                                selectedPrepHeader.codeLieuLivr,
                                sendOrderModal.orderBrouillard.panie,
                                newHeaderList[0].codeModeExpe
                              );
                              if (resLine) {
                                if (resLine.length === 0)
                                  navigate(ROUTES.SupplierSendOrderPage);
                                setOrderLines(resLine);
                              } else setOrderLines([]);
                              changeSelectedPrepHeader({ ...newHeaderList[0] });
                            }

                            const updatedBrouillard =
                              sendOrderModal.orderHeaderList &&
                              (await getOrderBrouillard(
                                sendOrderModal.codeTypeProd,
                                sendOrderModal.codeLieuPourQui,
                                sendOrderModal.niv,
                                newModeExpe
                                  ? {
                                      ...newHeaderList[0],
                                      codeModeExpe:
                                        sendOrderModal.orderBrouillard
                                          .codeModeExpe,
                                    }
                                  : sendOrderModal.orderHeaderList[0]
                              ));
                            if (updatedBrouillard) {
                              setOriginalBroui({
                                ...updatedBrouillard,
                                codeLieuEdi: current?.codeLieuEdi,
                              });
                              setSendOrderModal({
                                ...sendOrderModal,
                                orderHeaderList:
                                  newHeaderList.length > 0
                                    ? newHeaderList
                                    : sendOrderModal.orderHeaderList,
                                orderBrouillard: {
                                  ...updatedBrouillard,
                                  codeLieuEdi: current?.codeLieuEdi,
                                },
                              });

                              if (
                                newModeExpe &&
                                document.location.pathname ===
                                  ROUTES.SupplierSendOrderPage
                              ) {
                                const res = await getOrderPreparationHeaders(
                                  sendOrderModal.codeTypeProd,
                                  sendOrderModal.codeLieuPourQui
                                );
                                if (res) setOrderHeaders(res);
                              }
                            }
                          }

                          return;
                        }
                        if (sendOrderModal.orderHeaderList) {
                          handleClose();
                          setTimeout(() => {
                            setSendMultiOrderModal({
                              open: true,
                              orders: sendOrderModal.orderHeaderList || [],
                              mode: "envFourn",
                            });
                          }, 1000);

                          return;
                        }
                      }}
                      icon={(p) => <CheckCircleIcon {...p} />}
                    >
                      {!sameBrouil
                        ? "Valider les paramètres"
                        : "Envoi groupé par fournisseur"}
                    </Button>
                  )}
                {sendOrderModal.orderHeaderList &&
                  sendOrderModal.mode === "global" && (
                    <InfoSmallBold className="absolute bottom-0 left-3 z-60">{`${
                      waitRank + 1
                    }\\${
                      sendOrderModal.orderHeaderList?.length
                    }`}</InfoSmallBold>
                  )}
              </div>
            </div>
          )}
      </ModalContent>
    </Modal>
  );
};
