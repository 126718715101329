import { useCustomer } from "@features/customer/state/use-customer";
import { Order } from "@features/customer/type";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { useState } from "react";
import OrderReview from "./order-product-list";
import { Button } from "@atoms/button/button";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "@features/routes";
import { formatTime } from "@features/utils/format/dates";
import { useSetRecoilState } from "recoil";
import { HistoryCommandModalAtom } from "@molecules/history-command-modal";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { InfoSmall } from "@atoms/text";
import { formatAmount } from "@features/utils/format/strings";
import { Checkbox } from "@atoms/input/input-checkbox";

export const OrderHistory = (props: {
  clientID: string | null;
  clientMail?: string;
  clientPhone?: string;
}) => {
  const { getCustomerOrdersHistory, userHistory, historyPagination } =
    useCustomer();
  const { current: currentShop } = useShopLocations();
  const setHistoryCommandModal = useSetRecoilState(HistoryCommandModalAtom);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>();
  const [mailFilter, setMailFilter] = useState(false);
  const [phoneFilter, setPhoneFilter] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const referenceCommande: Column<Order> = {
    title: "No Commande",
    render: (order) => (order.numCde ? order.numCde : ""),
  };

  const referenceClient: Column<Order> = {
    title: "Référence Client",
    render: (order) => order.referCli,
  };

  // Je sais pas si je dois garder ou pas les minutes pour cette date
  const date: Column<Order> = {
    title: "Date",
    type: "date",
    render: (order) =>
      formatTime(order.dateCde as string, "fr-FR", {
        keepTime:
          new Date(order.dateCde as string).getSeconds() === 0 ? false : true,
        keepSeconds: false,
        keepDate: false,
      }),
  };

  const statut: Column<Order> = {
    title: "Statut",
    render: (order) =>
      order.clot ? "Clôturée" : order.valid ? "Validée" : "En cours",
  };

  const canalVente: Column<Order> = {
    title: "Canal vente",
    render: (order) => order.codeCanalVente,
  };

  const acompte: Column<Order> = {
    title: "Acompte",
    type: "price",
    render: (order) =>
      `${formatAmount(
        order.mtAcomp || 0,
        currentShop?.devisSymb,
        currentShop?.devisNbDecim
      )}`,
  };

  const select: Column<Order> = {
    render: () => (
      <div className="w-full flex justify-center">
        <Button>Voir détails</Button>
      </div>
    ),
  };

  useControlledEffect(() => {
    if (props.clientID) {
      getCustomerOrdersHistory(props.clientID, 1, 5);
    }
  }, [props.clientID]);

  return (
    <div className="grow">
      <div className="w-full flex flex-col lg:flex-row justify-end ">
        {props.clientMail && (
          <div className="w-full flex  text-center gap-2 text">
            <Checkbox
              className="self-center"
              value={mailFilter}
              onChange={() => setMailFilter(!mailFilter)}
            />
            <InfoSmall className="flex gap-1 items-center">
              Toutes les commandes liées au mail:
              <InfoSmall className="!text-blue-500">
                {`${props.clientMail}`}
              </InfoSmall>
            </InfoSmall>
          </div>
        )}
        {props.clientPhone && (
          <div className="w-full flex text-center gap-2">
            <Checkbox
              className="self-center"
              value={phoneFilter}
              onChange={() => setPhoneFilter(!phoneFilter)}
            />
            <InfoSmall className="flex gap-1 items-center">
              Toutes les commandes liées au tel:
              <InfoSmall className="!text-blue-500">
                {props.clientPhone}
              </InfoSmall>
            </InfoSmall>
          </div>
        )}
      </div>
      {selectedOrder && (
        <>
          <Button
            icon={(p) => <ArrowLeftIcon {...p} />}
            onClick={() => {
              setSelectedOrder(null);
            }}
          />
          <OrderReview
            order={selectedOrder}
            products={selectedOrder?.items || []}
            onChange={() => {}}
            readonly={true}
            enableBuy={true}
          />
        </>
      )}
      {!selectedOrder && (
        <Table
          key={`mail-${mailFilter}-phone-${phoneFilter}`}
          data={userHistory || []}
          onClick={(order) => {
            setHistoryCommandModal(false);
            setSelectedOrder(order);
            navigate(
              ROUTES.Order.replace(/:id/, order.numCde || "")
                .replace(/:created/, "0")
                .replace(/:ts/, Date.now().toString()),
              { state: { from: location.pathname } }
            );
          }}
          columns={[
            referenceCommande,
            referenceClient,
            canalVente,
            date,
            statut,
            acompte,
            select,
          ]}
          showPagination={
            historyPagination && historyPagination?.totalRows > 0
              ? "full"
              : false
          }
          initialPagination={{
            perPage: 5,
            page: 1,
            order: "ASC",
            orderBy: 0,
          }}
          onRequestData={async ({ page, perPage }) => {
            if (props.clientID && props.clientID !== "")
              getCustomerOrdersHistory(
                props.clientID || "",
                page,
                perPage,
                mailFilter && props.clientMail && props.clientMail !== ""
                  ? props.clientMail
                  : "",
                phoneFilter && props.clientPhone && props.clientPhone !== ""
                  ? props.clientPhone
                  : ""
              );
          }}
          onFetchExportData={async () => {
            return userHistory || [];
          }}
          total={historyPagination?.totalRows}
        ></Table>
      )}
    </div>
  );
};
