import { Modal, ModalContent } from "@atoms/modal/modal";
import { Pagination } from "@molecules/table/table";
import { atom, useRecoilState } from "recoil";
import { ColumnInfo, TableauBrun } from ".";

type TableauBrunModalType = {
  open: boolean;
  columnInfos: ColumnInfo[];
  getData?: () => Promise<any>;
  onRequestData?: (pagination: Pagination) => Promise<void>;
};

export const TableauBrunModalModalAtom = atom<TableauBrunModalType>({
  key: "UnknownArticlesModal",
  default: { open: false, columnInfos: [] },
});

export const TableauBrunModal = () => {
  const [tabModal, setTabModal] = useRecoilState(TableauBrunModalModalAtom);

  const handleClose = () => {
    setTabModal({ open: false, columnInfos: [] });
  };

  return (
    <Modal
      className="sm:max-w-[900px]"
      open={tabModal.open}
      onClose={() => handleClose()}
    >
      <ModalContent title={""}>
        <div className="w-full flex flex-col gap-4">
          <TableauBrun
            columnInfos={tabModal.columnInfos}
            rows={[{ t: "truc" }, { t: "truc" }]}
            getData={tabModal.getData}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};
