import {
  ArchiveIcon,
  BookOpenIcon,
  ChartBarIcon,
  DatabaseIcon,
  ShoppingCartIcon,
  TruckIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import { ROUTES } from "./routes";
import Env from "@config/environment";
import { LiaReplyAllSolid, LiaShippingFastSolid } from "react-icons/lia";
import { MdOutlineWarehouse } from "react-icons/md";
import { useShopLocations } from "./general/auth/state/use-store-location";

export type ModuleType = {
  name: string;
  subTitle?: string;
  route: (typeof ROUTES)[keyof typeof ROUTES];
  logo: React.ReactNode;
  color?: string;
  disabled?: boolean;
  hideModule?: boolean;
  useFrom?: boolean;
  functionHabilKey?: string;
};

export const useModules = () => {
  const { current } = useShopLocations();

  const modules: ModuleType[] = [
    {
      name: "Clients",
      subTitle: "Historique des commandes clients",
      logo: (
        <UserCircleIcon className="w-10 h-10 text-blue-500 dark:text-sky-700" />
      ),
      route: ROUTES.Clients,
      functionHabilKey: "Cli",
    },
    {
      name: "Produits",
      subTitle: "Rechercher des produits et passer des commandes",
      logo: (
        <ShoppingCartIcon className="w-10 h-10 text-blue-500 dark:text-sky-700" />
      ),
      route: ROUTES.Products,
      functionHabilKey: "Prod",
    },
    {
      name: "Réassort",
      subTitle: "Gestion du réassort",
      logo: <TruckIcon className="w-10 h-10 text-blue-500 dark:text-sky-700" />,
      route: ROUTES.FournReassort,
    },
    {
      name: "Réceptions",
      subTitle: "Création et historique des réceptions",
      logo: (
        <ArchiveIcon className="w-10 h-10 text-blue-500 dark:text-sky-700" />
      ),
      route: ROUTES.Receptions,
      functionHabilKey: "Recep",
      disabled: current && current?.recepActif ? false : true,
    },
    {
      name: "Retours",
      subTitle: "Gestion des retours fournisseurs",
      logo: (
        <LiaReplyAllSolid className="w-10 h-10 text-blue-500 dark:text-sky-700" />
      ),
      route: ROUTES.Unavailable,
      color: Env.isBeta ? "grey" : "",
      disabled: Env.isBeta,
    },
    {
      name: "Presse BL/BI",
      subTitle: "Module Presse/BL",
      logo: (
        <BookOpenIcon className="w-10 h-10 text-blue-500 dark:text-sky-700" />
      ),
      route: ROUTES.Unavailable,
      color: Env.isBeta ? "grey" : "",
      disabled: Env.isBeta,
    },
    {
      name: "Inventaire",
      subTitle: "Module inventaire",
      logo: (
        <DatabaseIcon className="w-10 h-10 text-blue-500 dark:text-sky-700" />
      ),
      route: ROUTES.Unavailable,
      color: Env.isBeta ? "grey" : "",
      disabled: Env.isBeta,
    },
    {
      name: "Tableau de bord",
      subTitle: "Visualisation des stocks, ventes...",
      logo: (
        <ChartBarIcon className="w-10 h-10 text-blue-500 dark:text-sky-700" />
      ),
      route: ROUTES.Dashboard,
      functionHabilKey: "Stat",
    },
    {
      name: "Envoi commandes",
      subTitle: "Envoi de commande à partir des commandes en préparation",
      logo: (
        <LiaShippingFastSolid className="w-10 h-10 text-blue-500 dark:text-sky-700" />
      ),
      route: ROUTES.SupplierSendOrderPage,
      functionHabilKey: "EnvCdeFrm",
      disabled: current && current?.envCdeActif ? false : true,
    },

    {
      name: "Commandes fournisseur",
      subTitle: "Encours et historique des commandes fournisseur",
      logo: (
        <MdOutlineWarehouse className="w-10 h-10 text-blue-500 dark:text-sky-700" />
      ),
      route: ROUTES.SupplierOrderPage,
    },

    // {
    //   name: "Préparation commande",
    //   subTitle: "Visualisation des commandes en préparation",
    //   logo: <ClipboardListIcon className="w-10 h-10 text-blue-500 dark:text-sky-700" />,
    //   route: Env.isBeta ? ROUTES.Unavailable : ROUTES.SupplierPrepOrderPage,
    //   color: Env.isBeta ? "grey" : "",
    //   disabled: Env.isBeta,
    // },

    {
      name: "Préparation commande fournisseur",
      logo: (
        <ChartBarIcon className="w-10 h-10 text-blue-500 dark:text-sky-700" />
      ),
      route: ROUTES.SupplierCommandOne,
      hideModule: true,
      useFrom: true,
    },

    {
      name: "Préparation commande fournisseur multi",
      logo: (
        <ChartBarIcon className="w-10 h-10 text-blue-500 dark:text-sky-700" />
      ),
      route: ROUTES.SupplierCommandMulti,
      hideModule: true,
      useFrom: true,
    },

    {
      name: "Préparation retour fournisseur",
      logo: (
        <ChartBarIcon className="w-10 h-10 text-blue-500 dark:text-sky-700" />
      ),
      route: ROUTES.SupplierRetourOne,
      hideModule: true,
      useFrom: true,
    },

    {
      name: "Préparation retour fournisseur multi",
      logo: (
        <ChartBarIcon className="w-10 h-10 text-blue-500 dark:text-sky-700" />
      ),
      route: ROUTES.SupplierRetourMulti,
      hideModule: true,
      useFrom: true,
    },
  ];

  return { modules };
};
