/** Describes application routes **/

export const ROUTES = {
  Login: "/login",
  Locations: "/login/locations",
  Recover: "/login/recover",
  Home: "/home",
  Products: "/products",
  Product: "/products/:type/:ean/:referGestion",
  Basket: "/products/basket/:id",
  BasketClientOrder: "/products/basket/:id/client-order",
  SettingsShortcuts: "/settings/shortcuts",
  ThemePage: "/settings/themes",
  Unavailable: "/unavailable",
  Clients: "/clients",
  ClientInfosPage: "/clients/infos",
  Order: "/clients/order/:id/:created/:ts",
  Receptions: "/receptions",
  SupplierPage: "/receptions/supplier/:codeFourn",
  ReceptionHeaderPage: "/receptions/initialize/:numPackage",
  OngoingReception: "/receptions/ongoing/:numRecep/:clotStep",
  SupplierRetourOne: "/suppliers/retourSimple/:type/:id",
  SupplierRetourMulti: "/suppliers/retourMulti/:basketId",
  SupplierCommandOne: "/suppliers/commandSimple/:type/:id",
  SupplierCommandMulti: "/receptions/suppliers/commandMulti/:basketId",
  SupplierOrderPage: "/fourn/orders",
  SupplierOrderLinePage:
    "/fourn/orders/:modeGest/:codeTypeProd/:codeFourn/:numCde/lines",
  SupplierPrepOrderPage: "/fourn/orders/prep",
  SupplierSendOrderPage: "/fournEnvoi/orders",
  SupplierSendOrderLinePage:
    "/fournEnvoi/orders/send/lines/:modeGest/:codeTypeProd:/:libTypeProd/:codeFourn",
  FournReassort: "/fourn/reassort",
  Dashboard: "/dashboard",
};
