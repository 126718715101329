import { fetchServer } from "@features/utils/fetch-server";
import {
  Bl,
  CommandType,
  OrderPrepBrouillard,
  ReassortProductLine,
  ReturnType,
  ShippingMode,
  SupplierOrderLine,
  SupplierOrderPrepHeader,
  SupplierOrderPrepLine,
  SupplierOrderRequestFilter,
  SupplierPackage,
} from "../types";
import { CommonPagination, CommonPaginationRequest } from "@features/types";
import { ApiResult } from "@features/general/common/types";

export class SupplierApiClient {
  static getMotifRefus = async () => {
    const data = await fetchServer("/supplier/return/reason");
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result.map((el: { code: any; lib: any }) => {
      return { label: el.lib, value: el.code };
    });
  };

  static getModesExpedition = async () => {
    const data = await fetchServer("/supplier/shippingmode", {
      reloadOn401: true,
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    // const toReturn = result.map((el: ShippingMode) => {
    //   return { ...el, lib: `${el.lib} (${el.code})` };
    // });
    // return result.map((el: { code: any; lib: any }) => {
    //   return { label: `${el.lib} (${el.code})`, value: el.code };
    // });
    return result as ShippingMode[];
  };

  static getFournBl = async (
    codeFourn?: string,
    numBL?: string,
    recepOuver?: boolean,
    recepTerm?: boolean
  ) => {
    const queryParams: { [key: string]: any } = {};

    if (codeFourn !== undefined && codeFourn !== "")
      queryParams.CodeFourn = codeFourn;
    if (numBL !== undefined && numBL !== "") queryParams.NumBl = numBL;
    if (recepOuver !== undefined) queryParams.RecepOuver = recepOuver;
    if (recepTerm !== undefined) queryParams.RecepTerm = recepTerm;

    const queryString = new URLSearchParams(queryParams).toString();

    const data = await fetchServer(`/supplier/delivery?${queryString}`);
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result;
  };

  static getSuppliers = async (
    pagination?: CommonPaginationRequest,
    filters?: any
  ) => {
    const data = await fetchServer("/supplier", {
      method: "POST",
      body: JSON.stringify({
        orderBy: pagination?.orderBy || "codeFourn",
        orderDir: pagination?.orderDir || "DESC",
        pageNumber: pagination?.pageNumber || 1,
        pageSize: pagination?.pageSize || 15,
        filters: { ...filters },
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as any;
  };

  static getFournBlPackages = async (bl: Bl) => {
    const data = await fetchServer(`/supplier/delivery/packages`, {
      method: "POST",
      body: JSON.stringify({
        ...bl,
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as SupplierPackage[];
  };

  static getFournBlLines = async (
    codeFourn?: string,
    numBL?: string,
    numColis?: string,
    idColis?: string,
    livrNbRef?: number,
    livrNb?: number
  ) => {
    const queryParams: { [key: string]: any } = {};

    if (codeFourn !== undefined && codeFourn !== "")
      queryParams.CodeFourn = codeFourn;
    if (numBL !== undefined && numBL !== "") queryParams.NumBl = numBL;
    if (numColis !== undefined) queryParams.numColis = numColis;
    if (idColis !== undefined) queryParams.idColis = idColis;
    if (livrNbRef !== undefined) queryParams.livrNbRef = livrNbRef;
    if (livrNb !== undefined) queryParams.livrNb = livrNb;

    const queryString = new URLSearchParams(queryParams).toString();

    const data = await fetchServer(
      `/supplier/delivery/package/lines?${queryString}`
    );
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result;
  };

  static getFournOrders = async (
    filters: SupplierOrderRequestFilter,
    requiredFilters: any,
    pagination?: CommonPaginationRequest
  ) => {
    const data = await fetchServer("/supplier/order", {
      method: "POST",
      body: JSON.stringify({
        orderBy: pagination?.orderBy || "dateCde",
        orderDir: pagination?.orderDir || "DESC",
        pageNumber: pagination?.pageNumber || 1,
        pageSize: pagination?.pageSize || 15,
        filters: { ...filters },
        requiredFilters: { ...requiredFilters },
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result;
  };

  static getSupplierDocument = async (
    id: string,
    documentType: "brouiCde" | "cde",
    modeGest: string,
    codeTypeProd: string,
    codeLieu: string,
    centr: boolean
  ) => {
    const data = await fetchServer("/supplier/order/document", {
      method: "POST",
      body: JSON.stringify({
        id: id,
        type: documentType,
        modeGestion: modeGest,
        codeTypeProd: codeTypeProd,
        codeLieu: codeLieu,
        centr: centr,
      }),
    });
    if (data.status !== 200 && data.status !== 204) {
      throw new Error("ERROR GENERATING PDF");
    }
    return await data.blob();
  };

  static getFournOrderLines = async (numCde: string) => {
    const data = await fetchServer(`/supplier/order/lines?NumCde=${numCde}`);
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as SupplierOrderLine[];
  };

  static createRetour = async (retourInfos: ReturnType, codeLieu: string) => {
    const data = await fetchServer("/supplier/return/preparation", {
      method: "PUT",
      body: JSON.stringify({
        codeLieuParQui: codeLieu,
        codeLieuPourQui: codeLieu,
        codeLieuExpe: codeLieu,
        codeLieuTrait: codeLieu,
        eaN13: retourInfos.id,
        qte: retourInfos.qteRetModif,
        codeMotifRet: retourInfos.codeMotifRet || "",
        codeFourn: retourInfos.codeFournHabit,
        panie: retourInfos.basket || "",
        demandeAut: retourInfos.demandeAut || false,
      }),
    });
    if (data.status !== 204) {
      throw new Error("Erreur lors de la création de retour");
    }
    return true;
  };

  static createOrder = async (orderInfos: CommandType, codeLieu: string) => {
    const data = await fetchServer("/supplier/order/preparation", {
      method: "PUT",
      body: JSON.stringify({
        codeLieu: codeLieu,
        eaN13: orderInfos.id,
        qte: orderInfos.qteOrdered,
        codeModeExpe: orderInfos.codeModeExpe,
        codeFourn: orderInfos.codeFourn || orderInfos.codeFournHabit,
        panie: orderInfos.basket,
        tauxNet: 0,
        tauxNetNet: 0,
        tauxAs: 0,
      }),
      reloadOn401: false,
      reloadOn403: false,
    });
    if (data.status !== 204) {
      throw new Error("Erreur lors de la création de commande");
    }
    return true;
  };
  static getReassortLines = async (
    modeGestion: string,
    codeTypeProduct?: string,
    pagination?: CommonPaginationRequest,
    filters?: any
  ) => {
    const data = await fetchServer("/supplier/reassort", {
      method: "POST",
      body: JSON.stringify({
        orderBy: pagination?.orderBy || "dateDernVente",
        orderDir: pagination?.orderDir || "DESC",
        pageNumber: pagination?.pageNumber || 1,
        pageSize: pagination?.pageSize || 100,
        modeGestion: modeGestion,
        codeTypeProduct: codeTypeProduct || "",
        filters: filters,
      }),
    });

    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return {
      items: result.items,
      pagination: {
        pageNumber: result.pageNumber,
        pageSize: result.pageSize,
        pageCount: result.pageCount,
        totalRows: result.totalRows,
        rowsReturned: result.rowsReturned,
      },
    } as {
      items: ReassortProductLine[];
      pagination: CommonPagination;
    };
  };

  static updateReassortLine = async (
    id: string,
    ean: string,
    qte: number = 0,
    panie: string = "",
    codeEtat: string,
    logi: string
  ) => {
    const data = await fetchServer("/supplier/reassort", {
      method: "PATCH",
      body: JSON.stringify({
        id: id,
        ean: ean,
        qte: qte,
        panie: panie,
        logi: logi,
        codeEtat: codeEtat,
        codeFourn: "",
      }),
    });

    if (data.status !== 204) {
      const result = await data.json();
      throw new Error(result.title);
    }
    return true;
  };

  static getOrderPreparationHeader = async (
    codeTypeProd?: string,
    pourQui?: string,
    niv?: boolean
  ) => {
    const queryParams: { [key: string]: any } = {};

    if (codeTypeProd !== undefined && codeTypeProd !== "")
      queryParams.codeTypeProd = codeTypeProd;
    if (pourQui !== undefined && pourQui !== "")
      queryParams.CodeLieuPourQui = pourQui;
    if (niv !== undefined) queryParams.niv = niv;

    const queryString = new URLSearchParams(queryParams).toString();
    const data = await fetchServer(
      `/supplier/order/preparation/grp?${queryString}`,
      {
        method: "GET",
      }
    );
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as SupplierOrderPrepHeader[];
  };
  static deleteOrderPreparationHeader = async (
    orderHeader: SupplierOrderPrepHeader
  ) => {
    const data = await fetchServer("/supplier/order/preparation/grp", {
      method: "DELETE",
      body: JSON.stringify({
        codeTypeProd: orderHeader.codeTypeProd,
        codeLieuPourQui: orderHeader.codeLieuPourQui,
        niv: orderHeader.niv,
        codeFourn: orderHeader.codeFourn,
        panie: orderHeader.panie,
        codeModeExpe: orderHeader.codeModeExpe,
        codeLieuLivr: orderHeader.codeLieuLivr,
      }),
    });
    if (data.status !== 204) {
      const result = await data.json();
      throw new Error(result.title);
    }
    return true;
  };

  static updateOrderPreparationLine = async (
    orderLine: SupplierOrderPrepLine,
    codeModeExpe: string,
    panie: string,
    qte: number,
    codeFourn: string
  ) => {
    const data = await fetchServer(`/supplier/order/preparation`, {
      method: "PATCH",
      body: JSON.stringify({
        pk: {
          codeFaitGener: orderLine.codeFaitGener,
          codeLieuParQui: orderLine.codeLieuParQui,
          codeLieuPourQui: orderLine.codeLieuPourQui,
          ean13: orderLine.ean13,
          idFaitGener: orderLine.idFaitGener,
          numBroui: orderLine.numBroui,
          seq: orderLine.seq,
        },

        codeModeExpe: codeModeExpe,
        panie: panie,
        qte: qte,
        codeFourn: codeFourn,
      }),
    });
    if (data.status !== 204) {
      const result = await data.json();
      throw new Error(result.title);
    }
    return true;
  };

  static updateOrderBrouillard = async (
    codeTypeProd: string,
    modeGest: string,
    niv: boolean,
    codeLieuPourQui: string,
    codeLieuEdi: string,
    brouillards: OrderPrepBrouillard[]
  ) => {
    const toSendBrouillards = brouillards.map((b) => {
      if (b.dateLivrPrev instanceof Date) {
        const localDate = new Date(
          b.dateLivrPrev.getTime() - b.dateLivrPrev.getTimezoneOffset() * 60000
        );
        return {
          ...b,
          dateLivrPrev: localDate.toISOString(), // 🔥 On force une string ISO pour l'API
        };
      }
      return { ...b };
    });

    const data = await fetchServer(
      `/supplier/order/preparation/grp/brouillard`,
      {
        method: "PATCH",
        body: JSON.stringify({
          codeTypeProd: codeTypeProd,
          modeGest: modeGest,
          niv: niv,
          codeLieuEdi: codeLieuEdi,
          codeLieuPourQui: codeLieuPourQui,
          brouillards: toSendBrouillards.map((b) => {
            return { ...b };
          }),
        }),
      }
    );
    const result = await data.json();

    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as { response: string; msg: string }[];
  };

  static updateOrderPreparationLineGrp = async (
    orderHeader: SupplierOrderPrepHeader,
    codeModeExpe: string,
    panie: string
  ) => {
    const data = await fetchServer(`/supplier/order/preparation/grp`, {
      method: "PATCH",
      body: JSON.stringify({
        pk: {
          codeTypeProd: orderHeader.codeTypeProd,
          codeLieuPourQui: orderHeader.codeLieuPourQui,
          niv: orderHeader.niv,
          codeFourn: orderHeader.codeFourn,
          panie: orderHeader.panie,
          codeModeExpe: orderHeader.codeModeExpe,
          codeLieuLivr: orderHeader.codeLieuLivr,
        },
        codeModeExpe: codeModeExpe,
        panie: panie,
      }),
    });
    if (data.status !== 204) {
      const result = await data.json();
      throw new Error(result.title);
    }
    return true;
  };

  static getOrderPreparationLines = async (
    codeTypeProd?: string,
    pourQui?: string,
    niv?: boolean,
    codeFourn?: string,
    panie?: string,
    codeModeExpe?: string,
    codeLieuLivr?: string
  ) => {
    const queryParams: { [key: string]: any } = {};
    if (codeTypeProd !== undefined && codeTypeProd !== "")
      queryParams.codeTypeProd = codeTypeProd;
    if (pourQui !== undefined && pourQui !== "") queryParams.pourQui = pourQui;
    if (niv !== undefined) queryParams.niv = niv;
    if (codeFourn !== undefined && codeFourn !== "")
      queryParams.codeFourn = codeFourn;
    if (panie !== undefined && panie !== "") queryParams.panie = panie;
    if (codeModeExpe !== undefined && codeModeExpe !== "")
      queryParams.codeModeExpe = codeModeExpe;
    if (codeLieuLivr !== undefined && codeLieuLivr !== "")
      queryParams.codeLieuLivr = codeLieuLivr;
    const queryString = new URLSearchParams(queryParams).toString();

    const data = await fetchServer(
      `/supplier/order/preparation/grp/lines?${queryString}`,
      {
        method: "GET",
        reloadOn401: false,
        reloadOn403: false,
      }
    );

    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as SupplierOrderPrepLine[];
  };

  static deleteOrderPreparationLine = async (
    orderLine: SupplierOrderPrepLine
  ) => {
    const data = await fetchServer("/supplier/order/preparation", {
      method: "DELETE",
      body: JSON.stringify({
        codeFaitGener: orderLine.codeFaitGener,
        codeLieuParQui: orderLine.codeLieuParQui,
        codeLieuPourQui: orderLine.codeLieuPourQui,
        ean13: orderLine.ean13,
        idFaitGener: orderLine.idFaitGener,
        numBroui: orderLine.numBroui,
        seq: orderLine.seq,
      }),
    });
    if (data.status !== 204) {
      const result = await data.json();
      throw new Error(result.title);
    }
    return true;
  };

  static createOrderBrouillard = async (
    codeTypeProd: string,
    codeLieuPourQui: string,
    niv: boolean,
    order: SupplierOrderPrepHeader,
    forceReturn: boolean = false
  ) => {
    const data = await fetchServer(
      `/supplier/order/preparation/grp/brouillard?forceReturn=${forceReturn}`,
      {
        method: "PUT",
        body: JSON.stringify({
          codeTypeProd: codeTypeProd,
          codeLieuPourQui: codeLieuPourQui,
          niv: niv,
          codeFourn: order.codeFourn,
          panie: order.panie,
          codeModeExpe: order.codeModeExpe,
          codeLieuLivr: order.codeLieuLivr,
        }),
      }
    );
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as ApiResult;
  };

  static getOrderBrouillard = async (
    codeTypeProd: string,
    codeLieuPourQui: string,
    niv: boolean,
    order: SupplierOrderPrepHeader
  ) => {
    const data = await fetchServer(
      `/supplier/order/preparation/grp/brouillard`,
      {
        method: "POST",
        body: JSON.stringify({
          codeTypeProd: codeTypeProd,
          codeLieuPourQui: codeLieuPourQui,
          niv: niv,
          codeFourn: order.codeFourn,
          panie: order.panie,
          codeModeExpe: order.codeModeExpe,
          codeLieuLivr: order.codeLieuLivr,
        }),
      }
    );
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return {
      ...result,
      oldCodeModeExpe: result.codeModeExpe,
    } as OrderPrepBrouillard;
  };

  static sendFournOrder = async (
    codeTypeProd: string,
    codeLieuPourQui: string,
    niv: boolean,
    order: SupplierOrderPrepHeader,
    brouil: OrderPrepBrouillard
  ) => {
    const data = await fetchServer(`/supplier/order/preparation/grp/send`, {
      method: "PUT",
      body: JSON.stringify({
        action: "VAL", //REM dans le cas d'une centrale
        codeFourn: order.codeFourn,
        codeTypeProd: codeTypeProd,
        codeLieuPourQui: codeLieuPourQui,
        panie: order.panie,
        codeModeExpe: order.codeModeExpe,
        codeLieuLivr: order.codeLieuLivr,
        comm: brouil.comm,
        codeOpeFourn: brouil.refer,
        niv: niv,
        edi: order.edi,
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as { numCde: string };
  };

  static resetLinePrep = async (
    modeGest: string,
    codeTypeProd: string,
    codeLieuPourQui: string,
    niv: boolean,
    order: SupplierOrderPrepHeader
  ) => {
    const data = await fetchServer(
      `/supplier/order/preparation/grp/lines/reset?modeGest=${modeGest}`,
      {
        method: "POST",
        body: JSON.stringify({
          modeGest: modeGest,
          codeTypeProd: codeTypeProd,
          codeLieuPourQui: codeLieuPourQui,
          niv: niv,
          codeFourn: order.codeFourn,
          panie: order.panie,
          codeModeExpe: order.codeModeExpe,
          codeLieuLivr: order.codeLieuLivr,
        }),
      }
    );
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as OrderPrepBrouillard;
  };

  static processingOrderPrepHeader = async (
    codeTypeProd: string,
    codeLieuPourQui: string,
    niv: boolean,
    order: SupplierOrderPrepHeader,
    activationStatus: boolean
  ) => {
    const data = await fetchServer(
      `/supplier/processing?status=${activationStatus}`,
      {
        method: "PATCH",
        body: JSON.stringify({
          codeTypeProd: codeTypeProd,
          codeLieuPourQui: codeLieuPourQui,
          niv: niv,
          codeFourn: order.codeFourn,
          panie: order.panie,
          codeModeExpe: order.codeModeExpe,
          codeLieuLivr: order.codeLieuLivr,
        }),
      }
    );
    if (data.status !== 204) {
      const result = await data.json();
      throw new Error(result.title);
    }
    return true;
  };
}
