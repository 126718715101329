import { useRecoilState } from "recoil";
import { DashboardAPIClient } from "../api-client/api-client";
import { LoadingState } from "@features/utils/store/loading-state-atom";

export const useStatistics = () => {
  const [loading, setLoading] = useRecoilState(
    LoadingState(["useStatistics", false])
  );
  const getSalesRevenue = async (
    codeLieu: string,
    canalVente: string,
    typeStat: string,
    periodDeb?: Date | null,
    periodFin?: Date | null
  ) => {
    setLoading(true);
    try {
      const res = await DashboardAPIClient.getSalesRevenue(
        codeLieu,
        canalVente,
        typeStat,
        periodDeb,
        periodFin
      );
      setLoading(false);
      return res;
    } catch {
      setLoading(false);
    }
    return;
  };
  return { loading, getSalesRevenue };
};
